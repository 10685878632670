import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { EventDataModel } from '../models/eventDataModel';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest.service';
import { FormDataService } from '../services/form-data.service';
import { Utility } from '../services/utility.service';
import { HCLineConfig } from './hc-line-config';
import { FormField } from '../models/formField';
import { FormFieldType } from '../models/formField.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hc-line-data',
  templateUrl: './hc-line-data.component.html',
  styleUrls: ['./hc-line-data.component.scss']
})
export class HcLineDataComponent implements OnInit, OnChanges, OnDestroy {

  ready: boolean = false;
  controls: FormField[] = HCLineConfig;
  lineDataForm: FormGroup;
  protected updateLineControlDataSubscription: Subscription;
  protected updateLineControlsListDataSubscription: Subscription;

  @Input() lastCTR: any[] = [];
  @Input() CTR: any[] = [];
  @Input() title: string;
  @Input() disable: boolean = false;

  @Output() change = new EventEmitter<EventDataModel>();
  @Output() leave = new EventEmitter<any>();

  get items() { return this.lineDataForm.controls.items as FormArray; }

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private formDataService: FormDataService,
    private utility: Utility) { }

  ngOnInit() {
    this.lineDataForm = this.fb.group({
      items: new FormArray([])
    });
    this.generateItemsFormArray();

    this.updateLineControlDataSubscription = this.formDataService.updateLineControlData$.subscribe((data: EventDataModel) => {
      const itemGroup = this.items.controls[data.rowIndex] as FormGroup;
      if (itemGroup) {
        itemGroup.controls[data.control].setValue(data.data);
      }
    });

    this.updateLineControlsListDataSubscription = this.formDataService.updateLineControlsListData$.subscribe((items: EventDataModel[]) => {
      items.forEach((item: EventDataModel) => {
        const itemGroup = this.items.controls[item.rowIndex] as FormGroup;
        if (itemGroup) {
          itemGroup.controls[item.control].setValue(item.data);
        }
      });
    });
  }

  ngOnChanges() {
    this.updateItemsData();
  }

  generateItemsFormArray() {
    for (let i = 0; i < 8; i++) {
      this.createItemFormGroup({});
    }
    this.ready = true;
    this.updateItemsData();
  }

  updateItemsData() {
    if (this.lineDataForm) {
      const lineRows = this.items.controls as [];
      for (let i = 0; i < lineRows.length; i++) {
        const row = lineRows[i] as FormGroup;
        if (this.CTR[i]) {
          row.controls.ctr.setValue(this.CTR[i].ctr);
          row.controls.qty.setValue(this.CTR[i].qty);
          row.controls.unitPrice.setValue(this.CTR[i].unitPrice);
          row.controls.amount.setValue(this.CTR[i].amount);
          row.controls.adjustment1.setValue(this.CTR[i].adjustment1);
          row.controls.adjustment2.setValue(this.CTR[i].adjustment2);
        }
      }
    }
  }

  createItemFormGroup(item: any) {
    if (this.controls && this.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.controls.length; i++) {
        const control: FormField = this.controls[i];
        let validators: any[] = [];
        if (control.required) {
          validators.push(Validators.required);
        }
        if (control.type === FormFieldType.Email) {
          validators.push(Validators.email);
        }

        let data: any;
        if (control.type === FormFieldType.DateTime) {
          data = this.utility.convertStringToDateObj(item[control.label]);
        } else {
          data = item[control.label];
        }
        group[control.label] = new FormControl(data, validators);
      }
      this.items.push(this.fb.group(group));
    }
  }

  changeControl(label: string, index: number) {
    if (typeof label === 'string') {
      const itemGroup = this.items.controls[index] as FormGroup;
      this.change.emit({
        control: label,
        data: itemGroup.controls[label].value,
        rowIndex: index
      });
    }
  }

  leaveControl() {
    this.leave.emit(this.items.value);
  }

  getLastCTR(index: number, col: number) {
    if (this.lastCTR[col]) {
      if (index === 0) {
        return this.lastCTR[col].ctr;
      } else if (index === 2) {
        return this.lastCTR[col].qty;
      } else if (index === 4) {
        return this.lastCTR[col].unitPrice;
      } else if (index === 6) {
        return this.lastCTR[col].amount;
      } else if (index === 7) {
        return this.lastCTR[col].adjustment1;
      } else if (index === 8) {
        return this.lastCTR[col].adjustment2;
      }
    } else {
      return 0;
    }
  }

  getControl(index: number): FormField {
    if (index % 2 === 0 && index <= 6) {
      return this.controls[index / 2];
    } else if (index === 7 || index === 8) {
      return this.controls[index - 3];
    }
  }

  getFormGroup(index: number): FormGroup {
    const lineRows = this.items.controls as [];
    return lineRows[index] as FormGroup;
  }

  ngOnDestroy() {
    this.updateLineControlDataSubscription ? this.updateLineControlDataSubscription.unsubscribe() : null;
    this.updateLineControlsListDataSubscription ? this.updateLineControlsListDataSubscription.unsubscribe() : null;
  }
}
