import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ActionsComponent } from './actions/actions.component';
import { DataTableComponent } from './data-table/data-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableService } from './services/data-table.service';
import { SessionService } from './services/session.service';
import { AddItemComponent } from './add-item/add-item.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { AutofocusDirective } from './Directives/autofocus.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortableHeaderDirective } from './Directives/sortable-header.directive';
import { HeaderDataComponent } from './header-data/header-data.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { AddDropdownItemModalComponent } from './modals/add-dropdown-item-modal/add-dropdown-item-modal.component';
import { SelectDropdownItemModalComponent } from './modals/select-dropdown-item-modal/select-dropdown-item-modal.component';
import { AddDropdownItemComponent } from './add-dropdown-item/add-dropdown-item.component';
import { AddCustomerDropdownComponent } from './modals/components/customer/add-customer-dropdown.component';
import { SelectCustomerDropdownComponent } from './modals/components/customer/select-customer-dropdown.component';
import { LineDataComponent } from './line-data/line-data.component';
import { FormFieldService } from './services/form-field.service';
import { UppercaseInputDirective } from './Directives/uppercase.directive';
import { SectionDataComponent } from './section-data/section-data.component';
import { Utility } from './services/utility.service';
import { SalesLineDataComponent } from './sales-line-data/sales-line-data.component';
import { HcLineDataComponent } from './hc-line-data/hc-line-data.component';
import { HcLoadLineDataComponent } from './hcload-line-data/hcload-line-data.component';
import { ObLineDataComponent } from './ob-line-data/ob-line-data.component';
import { LineDataMasterComponent } from './line-datamaster/line-datamaster.component';
import { StockCountLineDataComponent } from './line-stockcount/line-stockcount.component';
import { ResetCounterLineDataComponent } from './line-resetcounter/line-resetcounter.component';
import { CssLoaderComponent } from './css-loader/css-loader.component';
import { CollectionJournalLineDataComponent } from './line-collectionjournal/line-collectionjournal.component';
import { DataTableLazyLoadComponent } from './data-table-lazy-load/data-table-lazy-load.component';
import { CounterAmendmentLineDataComponent } from './line-counteramendment/line-counteramendment.component';
import { ControlLoaderComponent } from './control-loader/control-loader.component';

@NgModule({
  declarations: [
    ActionsComponent,
    DataTableComponent,
    AddItemComponent,
    FormFieldComponent,
    SortableHeaderDirective,
    AutofocusDirective,
    UppercaseInputDirective,
    HeaderDataComponent,
    LineDataComponent,
    AddDropdownItemModalComponent,
    SelectDropdownItemModalComponent,
    AddDropdownItemComponent,
    AddCustomerDropdownComponent,
    SelectCustomerDropdownComponent,
    SectionDataComponent,
    ObLineDataComponent,
    SalesLineDataComponent,
    HcLineDataComponent,
    HcLoadLineDataComponent,
    LineDataMasterComponent,
    StockCountLineDataComponent,
    ResetCounterLineDataComponent,
    CssLoaderComponent,
    CollectionJournalLineDataComponent,
    DataTableLazyLoadComponent,
    CounterAmendmentLineDataComponent,
    ControlLoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    DigitOnlyModule, // https://github.com/changhuixu/ngx-digit-only
  ],
  exports: [
    FormFieldComponent,
    ActionsComponent,
    DataTableComponent,
    DataTableLazyLoadComponent,
    AddItemComponent,
    HeaderDataComponent,
    LineDataComponent,
    SectionDataComponent,
    ObLineDataComponent,
    SalesLineDataComponent,
    HcLineDataComponent,
    HcLoadLineDataComponent,
    SortableHeaderDirective,
    LineDataMasterComponent,
    StockCountLineDataComponent,
    ResetCounterLineDataComponent,
    CollectionJournalLineDataComponent,
    CssLoaderComponent,
    CounterAmendmentLineDataComponent
  ],
  providers: [
    FormFieldService,
    DecimalPipe,
    Utility
  ],
  entryComponents: [
    AddDropdownItemModalComponent,
    SelectDropdownItemModalComponent
  ]
})
export class SharedModule { }
