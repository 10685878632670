import { Component } from '@angular/core';
import { DataTableConfig } from 'src/app/Shared/models/dataTableConfig';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';
import { EnumTextCase } from 'src/app/Shared/models/textCase.enum';
import { AddItemConfig } from 'src/app/Shared/models/addItemConfig';

@Component({
  selector: 'app-select-customer-dropdown',
  template: `<app-data-table *ngIf="viewTable" [config]="config" [pageSize]="5" [dropdown]="true" (add)="addItem()" (edit)="editItem($event)"></app-data-table>
            <add-item *ngIf="viewAdd" [config]="addItemConfig" (back)="backToTable($event)"></add-item>`
})
export class SelectCustomerDropdownComponent {

  config: DataTableConfig = {
    title: 'Customer',
    idProp: 'code',
    getApiUrl: '/Customer/GetAll',
    createUrl: '/test/customer',
    deleteApiUrl: '/Customer/Delete',
    headers: [{
      name: 'Code',
      prop: 'code'
    }, {
      name: 'Name',
      prop: 'name'
    }, {
      name: 'Address',
      prop: 'address'
    },
    {
      name: 'City',
      prop: 'city'
    }],
    selctionType: 'single'
  };

  addItemConfig: AddItemConfig = {
    idProp: 'code',
    title: 'Customer',
    getApiUrl: '/Customer/GetByCode',
    saveApiUrl: '/Customer/Create',
    updateApiUrl: '/Customer/Update',
    returnUrl: '/test/customer',
    dropdownView: true,
    controls: [
      [
        {
          type: FormFieldType.TextBox,
          label: 'code',
          name: 'Code',
          required: true,
          autofocus: true,
          textFormat: EnumTextCase.UpperCase
        },
        {
          type: FormFieldType.TextBox,
          label: 'name',
          name: 'Name'
        }
      ], [
        {
          type: FormFieldType.TextBox,
          label: 'searchName',
          name: 'Search Name'
        },
        {
          type: FormFieldType.TextBox,
          label: 'name2',
          name: 'Name 2'
        }
      ], [
        {
          type: FormFieldType.TextBox,
          label: 'address',
          name: 'Address'
        },
        {
          type: FormFieldType.TextBox,
          label: 'address2',
          name: 'Address 2'
        }
      ], [
        {
          type: FormFieldType.TextBox,
          label: 'city',
          name: 'City'
        },
        {
          type: FormFieldType.PhoneNumber,
          label: 'contact',
          name: 'Contact No'
        }
      ], [
        {
          type: FormFieldType.PhoneNumber,
          label: 'phoneNo',
          name: 'Phone No',
        },
        {
          type: FormFieldType.TextBox,
          label: 'faxNo',
          name: 'Fax No'
        },
      ],
      [
        {
          type: FormFieldType.TextBox,
          label: 'county',
          name: 'Country'
        },
        {
          type: FormFieldType.TextBox,
          label: 'postCode',
          name: 'Post Code'
        }
      ], [
        {
          type: FormFieldType.Email,
          label: 'eMail',
          name: 'E-Mail',
        },
        {
          type: FormFieldType.Number,
          label: 'amount',
          name: 'Amount',
          decimal: true
        },
        {
          type: FormFieldType.DropDown,
          label: 'locationCode',
          name: 'Location Code',
          apiUrl: '/LocationinZone/GetAll',
          bindLabel: 'code',
          bindValue: 'name'
        },
        {
          type: FormFieldType.Checkbox,
          label: 'blocked',
          name: 'Blocked',
        },
      ], [
        {
          type: FormFieldType.DropDown,
          label: 'billtoCustomerNo',
          name: 'Bill to Customer No',
          apiUrl: '/BillToCustomerNo/GetAll',
          bindLabel: 'code',
          bindValue: 'name'
        },
        {
          type: FormFieldType.DropDown,
          label: 'ownership',
          name: 'Ownership',
          apiUrl: '/Ownership/GetAll',
          bindLabel: 'code',
          bindValue: 'description'
        }
      ], [
        {
          type: FormFieldType.TextBox,
          label: 'gst',
          name: 'SST',
        },
        {
          type: FormFieldType.TextBox,
          label: 'gstNo',
          name: 'SST No',
        },
      ], [
        {
          type: FormFieldType.TextBox,
          label: 'contactPerson',
          name: 'Contact Person',
        },
        {
          type: FormFieldType.TextBox,
          label: 'attentionto',
          name: 'Attention To',
        }

      ], [
        {
          type: FormFieldType.DropDown,
          label: 'baseNew',
          name: 'Base New',
          items: [
            {
              name: 'base'

            },
            {
              name: 'new'

            }
          ],
          bindLabel: 'name',
          bindValue: 'name'
        },
        {
          type: FormFieldType.DropDown,
          label: 'paymentTermsCode',
          name: 'Payment Terms Code',
          apiUrl: '/PaymentTermsCode/GetAll',
          bindLabel: 'code',
          bindValue: 'description'
        }
      ], [
        {
          type: FormFieldType.DropDown,
          label: 'paymentMethodCode',
          name: 'Payment Method Code',
          apiUrl: '/PaymentMethodCode/GetAll',
          bindLabel: 'code',
          bindValue: 'description'
        },
        {
          type: FormFieldType.DropDown,
          label: 'customerGroup',
          name: 'Customer Group',
          apiUrl: '/CustomerGroup/GetAll',
          bindLabel: 'code',
          bindValue: 'groupName'
        }
      ], [
        {
          type: FormFieldType.Number,
          label: 'creditLimit',
          name: 'Credit Limit',
          decimal: true
        },
        {
          type: FormFieldType.Checkbox,
          label: 'priority',
          name: 'Priority',
        }
      ], [
        {
          type: FormFieldType.DropDown,
          label: 'salespersonCode',
          name: 'Sales Person Code',
          apiUrl: '/SalesPersonCode/GetAll',
          bindLabel: 'code',
          bindValue: 'salesPersonName'
        },
        {
          type: FormFieldType.DropDown,
          label: 'locationCode',
          name: 'Location Code',
          apiUrl: '/LocationinZone/GetAll',
          bindLabel: 'code',
          bindValue: 'name'
        }
      ]
    ]
  };

  public viewTable: boolean = true;
  public viewAdd: boolean = false;

  addItem() {
    this.addItemConfig.id = 'add';
    this.viewTable = false;
    this.viewAdd = true;
  }

  editItem(item: any) {
    this.addItemConfig.id = item[this.addItemConfig.idProp];
    this.viewTable = false;
    this.viewAdd = true;
  }

  backToTable(item: any) {
    this.viewAdd = false;
    this.viewTable = true;
  }

}
