import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'control-loader',
  templateUrl: './control-loader.component.html',
  styleUrls: ['./control-loader.component.scss']
})
export class ControlLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
