// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from '../Shared/services/session.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class InterceptService implements HttpInterceptor {
  private apiUrl: string = environment.api;

  constructor(private router: Router,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private httpclient: HttpClient,
    private cookie: CookieService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.sessionService.Token;

    // add logout beacuse we have call logout api after session expired

    if (token || request.url.toLowerCase().includes('/login') || request.url.toLowerCase().includes('/logout')) {
      this.sessionService.Token = token;
      return next.handle(request).pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
            }
          },
          error => {
            if ((error.status && error.status === 401) && (error.statusText === 'Unauthorized')) {
              localStorage.removeItem('token');
              localStorage.removeItem('username');
              this.toastr.error('Invalid Token!');
              this.router.navigate(['/auth/login']);
            }
            // if (error.status && error.status === 503) {
            //   localStorage.removeItem('token');
            //   localStorage.removeItem('username');
            //   // this.toastr.error('Application is temporarily unavailable');
            //   this.router.navigate(['/auth/errorPage']);
            // }
            // else{

            // }
          }
        )
      );
    } else {

      // cooment out code beacuse we have call logout api after session expired

      localStorage.removeItem('token');
      //localStorage.removeItem('username');
      this.toastr.error('Session Expired!');
      //this.router.navigate(['/auth/login']);
      this.logOut();
    }
  }


  logOut() {
    let username = this.sessionService.UserName;
    let token_cookie = this.cookie.get("token");
    this.httpclient.get(this.apiUrl + '/Users/Logout?email=' + username + '&token=' + token_cookie).subscribe((datalogout: any) => {
      //console.log(datalogout);
      this.sessionService.Token = null;
      this.sessionService.UserName = null;
      this.cookie.set("token", null);
      this.router.navigate(['/auth/login']);
    });
  }

}
