import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const ObLineConfig: FormField[] = [
  // {
  //   type: FormFieldType.Number,
  //   label: 'invoice',
  //   name: 'Invoice'
  // },
  {
    type: FormFieldType.Number,
    label: 'systemob',
    name: 'System OB'
  },
  {
    type: FormFieldType.Number,
    label: 'physicalob',
    name: 'Physical OB'
  },
  {
    type: FormFieldType.Number,
    label: 'sales',
    name: 'Sales'
  },
  {
    type: FormFieldType.Number,
    label: 'transfer',
    name: 'Load'
  },
  {
    type: FormFieldType.Number,
    label: 'transferReturn',
    name: 'Recall'
  },
  // {
  //   type: FormFieldType.Number,
  //   label: 'unitPrice',
  //   name: 'Unit Price'
  // },
  {
    type: FormFieldType.Number,
    label: 'adjustment1',
    name: 'TEST(O)'
  },
  {
    type: FormFieldType.Number,
    label: 'adjustment2',
    name: 'TEST(S)'
  },
  {
    type: FormFieldType.Number,
    label: 'unitPrice',
    name: 'Unit Price'
  },
  {
    type: FormFieldType.Number,
    label: 'amount',
    name: 'Amount'
  },
  {
    type: FormFieldType.TextBox,
    label: 'ownership',
    name: 'Ownership',
  }
];


export const ObLineCalculationConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'totalExclSST',
    name: 'Total Excl. SST'
  },
  {
    type: FormFieldType.Number,
    label: 'sst',
    name: 'SST'
  },
  {
    type: FormFieldType.Number,
    label: 'totalInclSST',
    name: 'Total Incl. SST'
  }
];
