import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const HCLineConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'ctr',
    name: ' CTR'
  },
  {
    type: FormFieldType.Number,
    label: 'qty',
    name: ' QTY'
  },
  {
    type: FormFieldType.Number,
    label: 'unitPrice',
    name: 'Unit Price'
  },
  {
    type: FormFieldType.Number,
    label: 'amount',
    name: 'Amount'
  },
  {
    type: FormFieldType.Number,
    label: 'adjustment1',
    name: 'Adjustment1'
  },
  {
    type: FormFieldType.Number,
    label: 'adjustment2',
    name: 'Adjustment2'
  }
];
