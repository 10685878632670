import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-dropdown-item-modal',
  templateUrl: './add-dropdown-item-modal.component.html'
})
export class AddDropdownItemModalComponent implements OnInit {

  public data: any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  saveItem(data: any) {
    this.data = data;
  }
}
