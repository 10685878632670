import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-dots',
  templateUrl: './footer-dots.component.html'
})
export class FooterDotsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
