import { Component, Output, EventEmitter } from '@angular/core';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';
import { AddDropdownItemConfig } from 'src/app/Shared/models/addDropdownItemConfig';

@Component({
  selector: 'app-add-customer-dropdown',
  template: '<add-dropdown-item [config]="config" (save)="saveItem($event)"></add-dropdown-item>'
})
export class AddCustomerDropdownComponent {

  config: AddDropdownItemConfig = {
    idProp: 'code',
    title: 'Customer',
    saveApiUrl: '/Customer/Create',
    updateApiUrl: '/Customer/Update',
    controls: [
      [
        {
          type: FormFieldType.TextBox,
          label: 'code',
          name: 'Code',
          required: true,
          autofocus: true
        },
        {
          type: FormFieldType.TextBox,
          label: 'name',
          name: 'Name'
        }
      ]
    ]
  };

  @Output() save = new EventEmitter<any>();

  saveItem(data: any) {
    this.save.emit(data);
  }
}
