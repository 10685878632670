import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormField } from '../models/formField';
import { FormFieldType } from '../models/formField.enum';
import { RestService } from '../services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderDataConfig } from '../models/headerDataConfig';
import { FormDataModel } from '../models/formDataModel';
import { Utility } from '../services/utility.service';

@Component({
  selector: 'section-data',
  templateUrl: './section-data.component.html'
})
export class SectionDataComponent implements OnInit {

  data: any;
  ready: boolean = false;
  sectionDataForm: FormGroup;

  @Input() config: HeaderDataConfig;
  @Output() change = new EventEmitter<any>();
  @Output() leave = new EventEmitter<FormDataModel>();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private restService: RestService,
    private utility: Utility) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        if (params.id !== 'add') {
          this.getItem(params.id);
        } else {
          this.data = {};
          this.createFormGroup();
        }
      } else {
        this.router.navigate([this.config.returnUrl]);
      }
    });
  }

  get formControls() { return this.sectionDataForm.controls; }

  getItem(id: string) {
    this.restService.get(this.config.getApiUrl + '?' + this.config.idProp + '=' + id).subscribe((response: any) => {
      if (response.model) {
        this.data = response.model;
        this.createFormGroup();
      } else {
        this.toastr.warning('Unable to find the header data.');
        this.router.navigate([this.config.returnUrl]);
      }
    }, (error) => {
      this.toastr.warning('Unable to find the header data.');
      this.router.navigate([this.config.returnUrl]);
    });
  }

  createFormGroup() {
    if (this.config.controls && this.config.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.config.controls.length; i++) {
        const column: FormField[] = this.config.controls[i];
        for (let j = 0; j < column.length; j++) {
          const control: FormField = column[j];
          let validators: any[] = [];
          if (control.required) {
            validators.push(Validators.required);
          }
          if (control.type === FormFieldType.Email) {
            validators.push(Validators.email);
          }

          let data: any;
          if (control.type === FormFieldType.DateTime) {
            data = this.utility.convertStringToDateObj(this.data[control.label]);
          } else {
            data = this.data[control.label];
          }
          group[control.label] = new FormControl(data, validators);
        }
      }
      this.sectionDataForm = this.fb.group(group);
      this.ready = true;
    }
  }

  changeControl(label: string) {
    this.change.emit({
      control: label,
      data: this.formControls[label].value
    });
  }

  leaveControl() {
    this.leave.emit({
      data: this.sectionDataForm.value,
      valid: this.sectionDataForm.valid
    });
  }
}
