import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableService } from '../../services/data-table.service';
import { DropdownModalResult } from '../../models/dropdownModalResult';
import { EnumDropdownComponentType } from '../../models/dropdownComponentType.enum';

@Component({
  selector: 'app-select-dropdown-item-modal',
  templateUrl: './select-dropdown-item-modal.component.html'
})
export class SelectDropdownItemModalComponent implements OnInit {

  public dropdownComponentType = EnumDropdownComponentType;
  data: DropdownModalResult = {
    records: [],
    selected: []
  };

  @Input() componentType: EnumDropdownComponentType;

  constructor(public activeModal: NgbActiveModal,
    private dataTableService: DataTableService) { }

  ngOnInit() {
    this.dataTableService.ItemSelected$.subscribe((data: any) => {
      this.data.selected = data;
    });

    this.dataTableService.data$.subscribe((data: any[]) => {
      this.data.records = this.dataTableService.data;
    });
  }

}
