import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const CollectionJournalLineConfig: FormField[] = [
  {
    type: FormFieldType.TextBox,
    label: 'documentType',
    name: 'Document Type',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'documentNo',
    name: 'Document No',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'locationCode',
    name: 'Location Code',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'locationName',
    name: 'Location Name',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'shortcutDimension2Code',
    name: 'VM Sub Category',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'accountNo',
    name: 'Customer Code',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'machineCode',
    name: 'Machine Code',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'csvAmount',
    name: 'CSV Amount',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'cashRM',
    name: 'Cash',
    decimal: true,
  },
  {
    type: FormFieldType.Number,
    label: 'touchandGO',
    name: 'Touch & Go',
    decimal: true,
  },
  {
    type: FormFieldType.Number,
    label: 'debitCard',
    name: 'Debit Card',
    decimal: true,
  },
  {
    type: FormFieldType.Number,
    label: 'eWallet',
    name: 'E-Wallet',
    decimal: true,
  },
  // {
  //   type: FormFieldType.Number,
  //   label: 'vat',
  //   name: 'SST %',
  //   decimal: true,
  // },
  {
    type: FormFieldType.Number,
    label: 'amount',
    name: 'Amount',
    decimal: true,
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'difference',
    name: 'Difference',
    decimal: true,
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'description',
    name: 'Remarks'
  },
  {
    type: FormFieldType.TextBox,
    label: 'ownership',
    name: 'Ownership',
    readonly:true
  },
];

export const CollectionJournalCalculationConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'totalCsvAmount',
    name: 'Total Csv Amount'
  },
  {
    type: FormFieldType.Number,
    label: 'totalAmount',
    name: 'Total Amount'
  },
  // {
  //   type: FormFieldType.Number,
  //   label: 'totalInclSST',
  //   name: 'Total Incl. SST'
  // }
];
