import { MenuGroup } from './models/menuGroup';

export const MenuItems: MenuGroup[] = [
  {
    title: 'Company Information',
    menu: {
      icon: 'pe-7s-culture',
      title: 'Company',
      childrens: [
        {
          title: 'Company',
          link: '/purchase/Company'
        },
        {
          title: 'Geo Location',
          link: '/purchase/geo-location'
        }
      ]
    }
  },
  {
    title: 'Sales',
    menu: {
      icon: 'pe-7s-cash',
      title: 'Sales',
      childrens: [
        {
          title: 'Sales Orders',
          link: '/purchase/saleOrder'
        },
        {
          title: 'Posted Sales Shipment',
          link: '/purchase/saleshipment'
        },
        {
          title: 'Posted Sales Invoice',
          link: '/purchase/salesinvoice'
        },

      ]
    }
  },
  // {
  //   title: 'Setup Inventory',
  //   menu: {
  //     icon: 'pe-7s-cash',
  //     title: 'Setup Inventory',
  //     childrens: [
  //       {
  //         title: 'Setup Inventory',
  //         link: '/purchase/SetupInventory/add'
  //       },

  //     ]
  //   }
  // },
  {
    title: 'Master 2.0',
    menu: {
      icon: 'pe-7s-notebook',
      title: 'Master 2.0',
      childrens: [
        {
          title: 'Product',
          link: '/test/productmaster'
        },
        {
          title: 'Van Location',
          link: '/test/location'
        },
        {
          title: 'Machine Location',
          link: '/test/machinelocation'
        },
        {
          title: 'Warehouse Location',
          link: '/test/warehouselocation'
        },
        {
          title: 'Item UOM',
          link: '/test/item'
        },
        {
          title: 'Payment Method Code',
          link: '/test/payment'
        },
        {
          title: "Payment Terms Code",
          link: "/test/paymentterms",
        },
        {
          title: 'Ownership',
          link: '/sales/ownership'
        },
        {
          title: "Customer Price Category",
          link: "/test/customerprice",
        },
        {
          title: "Customer Group",
          link: "/test/customergroup",
        },
        {
          title: "Bank Account",
          link: "/test/bank",
        },
        {
          title: "Customer",
          link: "/test/customer",
        },
        {
          title: 'Machine',
          link: '/machine/master'
        },
        {
          title: 'Supplier',
          link: '/purchase/supplier'
        },
        {
          title: 'Vending Machine Model',
          link: '/test/modelnumber'
        },
        {
          title: 'Status',
          link: '/test/status'
        },
        {
          title: 'Zone',
          link: '/test/zone'
        },
        {
          title: 'Item Category Code',
          link: '/test/product'
        },
        {
          title: 'Reason',
          link: '/test/reason'
        },


      ]
    }
  },

  {
    title: 'Master 3.0',
    menu: {
      icon: 'pe-7s-notebook',
      title: 'Master 3.0',
      childrens: [
        {
          title: 'Vending Machine Type',
          link: '/test/vending'
        },
        {
          title: 'Product VM Type',
          link: '/purchase/vmSubCategory'
        },
        {
          title: 'Service Man',
          link: '/test/serviceman'
        },
        {
          title: 'Define Product for Machine',
          link: '/test/defineproductmachine'
        },
        {
          title: 'Setup Counter & Define Vend Price',
          link: '/test/setupcounter'
        },
        {
          title: 'Define Product for Customer',
          link: '/test/productcustomer'
        },
        {
          title: 'Define Product for Zone',
          link: '/test/productzone'
        },
        {
          title: 'Master Transaction',
          link: '/test/mastertransaction'
        },
        {
          title: 'Machine Write Off',
          link: '/test/machinewriteoff'
        },
        {
          title: 'Accounting Period',
          link: '/test/accountingperiod'
        },
        {
          title: 'Delivery Person Master',
          link: '/test/deliveryperson'
        },
        {
          title: 'Shipment Method Code',
          link: '/test/shipmentmethod'
        },

        // {

        //   title: 'Define Product for Location',
        //   link: '/test/productlocation'
        // },
        {
          title: 'Define Product for Supplier',
          link: '/test/productsupplier'
        },
        {
          title: 'Location Type',
          link: '/test/locationtype'
        },
        {
          title: 'Country Master',
          link: '/test/country'
        },
        {
          title: 'Ship To Code',
          link: '/purchase/shiptoCode',
        },
        {
          title: 'Base UOM',
          link: '/test/baseuom'
        },
        {
          title: 'Sales Person',
          link: '/test/sales'
        },
      ]
    }
  },

  {
    title: 'Transfer',
    menu: {
      icon: 'pe-7s-paper-plane',
      title: 'Transfer',
      childrens: [
        {
          title: 'Transfer Order',
          link: '/test/transfer'
        },
        {
          title: 'Posted Transfer Shipping Order',
          link: '/test/transfershippingorder'
        },

        {
          title: 'Posted Transfer Receipt Order',
          link: '/test/transferreceiptorder'
        },


      ]
    }
  },


  {
    title: 'VC Sales Order',
    menu: {
      icon: 'pe-7s-cart',
      title: 'VC Sales Order',
      childrens: [
        {
          title: 'VC Sales Order',
          link: '/purchase/vcSalesOrder'
        },
        {
          title: 'Posted VC Sales Order',
          link: '/purchase/PostedvcSalesOrder',
        },
        {
          title: 'Machine Invoice Line',
          link: '/purchase/MachineInvoiceLine',
        },
      ]
    }
  },


  {
    title: 'Daily Operation',
    menu: {
      icon: 'pe-7s-timer',
      title: 'Daily Operation',
      childrens: [
        {
          title: 'Transfer to VAN',
          link: '/test/transfertovan'
        },
        {
          title: 'Transfer to Warehouse',
          link: '/test/transfertowarehouse'
        },

        {
          title: 'Posted Transfer Receipt',
          link: '/test/transfertodailyoperation'
        },


      ]
    }
  },
  // grn by arka
  {
    title: 'GRN',
    menu: {
      icon: 'pe-7s-news-paper',
      title: 'GRN',
      childrens: [
        {
          title: 'Good Receipt Note (GRN)',
          // title: 'Purchase Order(GRN)',
          link: '/purchase/purchaseOrder'
        },
        {
          // title: 'Purchase Return Order (GRTN)',
          title: 'Good Receipt Return Note (GRTN)',
          link: '/purchase/purchaseReturnOrder'
        },
        {
          // title: 'Posted Purchase Order(Posted GRN)',
          title: 'Posted Good Receipt Note (GRN)',
          link: '/purchase/postedpurchase'
        },
        {
          // title: 'Posted Purchase Return Order(Posted GRTN)',
          title: 'Posted Good Receipt Return Note (GRTN)',
          link: '/purchase/postedPurchaseReturnOrder'
        },
        {
          title: 'No Series',
          link: '/purchase/NoSeries'
        },
        {
          title: 'Setup No Series',
          link: '/purchase/SetupNoSerise'
        }
      ]
    }
  },
  // {
  //   title: 'REPORTs',
  //   menu: {
  //     icon: 'pe-7s-cash',
  //     title: 'Reports',
  //     childrens: [
  //       {
  //         title: 'GRN Report',
  //         link: '/purchase/postedpurchasereport'
  //       },

  //     ]
  //   }
  // },

  // grn by arka
  {
    title: 'Stock Adjusment',
    menu: {
      icon: 'pe-7s-note2',
      title: 'Stock Adjusment',
      childrens: [
        {
          title: 'Stock Adjusment',
          link: '/stockadjusment/stockadjusment/add'
        },
        {
          title: 'Item Ledger',
          link: '/stockadjusment/itemledgerstockadjusment'
        },
        {
          title: 'Physical Stock Count',
          link: '/stockadjusment/physicalstockadjusment'
        },
        {
          title: 'Reset Counter',
          link: '/stockadjusment/resetcounter'
        },
        // {
        //   title: 'Counter Amendment',
        //   link: '/stockadjusment/counteramendment'
        // },
        {
          title: 'Counter Amendment',
          link: '/stockadjusment/counteramendment'
        },
        {
          title: 'Posted Counter Amendment',
          link: '/stockadjusment/postedcounteramendment'
        },
        {
          title: 'Posted Physical Stock Count',
          link: '/stockadjusment/postedphysicalstockadjusment'
        },
        {
          title: 'Posted Reset Counter',
          link: '/stockadjusment/postedresetcounter'
        },


      ]
    }
  },

  {
    title: 'Collection Journal',
    menu: {
      icon: 'pe-7s-box1',
      title: 'Collection Journal',
      childrens: [
        {
          title: 'General Journal',
          link: '/stockadjusment/generalJournal'
        },
        {
          title: 'Collection Journal',
          link: '/stockadjusment/collectionjournal'
        },
        {
          title: 'Posted General Journal',
          link: '/stockadjusment/postedgeneraljournal'
        },
        {
          title: 'Posted Collection Journal',
          link: '/stockadjusment/postedcollectionjournal'
        }
      ]
    }
  },


  // {
  //   title: 'Physical Stock Adjusment',
  //   menu: {
  //     icon: 'pe-7s-cash',
  //     title: 'Physical Stock Adjusment',
  //     childrens: [
  //       {
  //         title: 'Physical Stock Adjusment',
  //         link: '/stockadjusment/physicalstockadjusment'
  //       }
  //     ]
  //   }
  // },

  // Scheme Settings
  {
    title: 'Scheme Settings',
    menu: {
      icon: 'pe-7s-edit',
      title: 'Scheme Settings',
      childrens: [
        {
          title: 'Scheme Setup',
          link: '/scheme/setup'
        },
        {
          title: 'Scheme Table',
          link: '/scheme/table'
        },
        {
          title: 'Scheme Calculation',
          link: '/scheme/calculation'
        }
      ]
    }
  },

  // Route Plans
  {
    title: 'Routes',
    menu: {
      icon: 'pe-7s-way',
      title: 'Routes',
      childrens: [
        {
          title: 'Holidays',
          link: '/route/holidays'
        },
        {
          title: 'Weekend and Holiday zone',
          link: '/route/zones'
        },
        {
          title: 'Route Plans',
          link: '/route/plans'
        },
        {
          title: 'Route Plan Calendar',
          link: '/route/plans/calendar'
        }
      ]
    }
  },

  // User Modules
  {
    title: 'User Management',
    menu: {
      icon: 'pe-7s-users',
      title: 'User Management',
      childrens: [
        {
          title: 'Page Configuration',
          link: '/users/pages'
        },
        {
          title: 'User Roles',
          link: '/users/roles'
        },
        {
          title: 'Users',
          link: '/users/users'
        },
        {
          title: 'User Access Control',
          link: '/users/useraccesscontrol'
        },
        {
          title: 'Data Activity Configuration',
          link: '/settings/data-activity'
        }
      ]
    }
  },

  // Reports
  {
    title: 'Reports',
    menu: {
      icon: 'pe-7s-display2',
      title: 'Reports',
      childrens: [
        {
          title: 'Reports',
          link: '/reports'
        },
        // {
        //   title: 'Monthly Analysis Reports',
        //   link: '/monthlyAnalysisReports'
        // }
      ]
    }
  },

  {
    title: 'Analysis Reports',
    menu: {
      icon: 'pe-7s-graph2',
      title: 'Analysis Reports',
      childrens: [
        {
          title: 'Inventory Movement Report',
          link: '/inventoryMovementReport'
        },
        {
          title: 'Productivity SOI Report',
          link: '/productivitysoireport'
        },
        {
          title: 'Extract Posted Stock Adjustment',
          link: '/ExtractPostedStockAdjustment'
        }
      ]
    }
  }

];
