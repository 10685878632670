import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { AddItemConfig } from '../models/addItemConfig';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormField } from '../models/formField';
import { FormFieldType } from '../models/formField.enum';
import { RestService } from '../services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { ActionsConfig } from '../models/actionsConfig';
import { EventDataModel } from '../models/eventDataModel';
import { Utility } from '../services/utility.service';

@Component({
  selector: 'add-item',
  templateUrl: './add-item.component.html'
})
export class AddItemComponent implements OnInit {

  data: any;
  update: boolean = false;
  ready: boolean = false;
  viewMode: boolean = false;
  addItemForm: FormGroup;
  present_productmaster: number = 0;
  present_productmaster_view: number = 0;
  actionsConfig: ActionsConfig = {
    showRight: true,
    showBack: true
  };

  @Input() config: AddItemConfig;
  @Input() autoGeneratedCode: boolean;
  @Input() set showResetPassword(value: boolean) {
    this.actionsConfig.showResetPassword = value;
  }
  @Output() back = new EventEmitter<any>();
  @Output() change = new EventEmitter<EventDataModel>();
  @Output() leave = new EventEmitter<any>();
  @Output() post = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private restService: RestService,
    private utility: Utility) {
  }

  ngOnInit() {
    if (this.config.showButtons === null || this.config.showButtons === undefined) {
      this.config.showButtons = true;
    }

    if (this.config.dropdownView) {
      if (this.config.id !== 'add') {
        this.update = true;
        this.getItem(this.config.id);
      } else {
        this.createFormGroup();
      }
    } else {
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          this.actionsConfig.showEdit = this.viewMode;
        }
      });

      this.activatedRoute.params.subscribe((params: any) => {
        if (params.id) {
          if (params.id !== 'add') {
            this.update = true;
            this.getItem(params.id);
          } else {
            this.createFormGroup();
          }
        } else {
          this.router.navigate([this.config.returnUrl]);
        }
      });
    }

    let ab = window.location.href;
    let url1 = ab.split("/");
    this.present_productmaster = url1.indexOf("productmaster")
    this.present_productmaster_view = url1.indexOf("view")
  }

  get formControls() { return this.addItemForm.controls; }

  getItem(id: string) {
    this.restService.get(this.config.getApiUrl + '?' + this.config.idProp + '=' + id).subscribe((response: any) => {
      if (response.model) {
        this.data = response.model;
        this.createFormGroup();
      } else {
        this.toastr.warning('Unable to find the item');
        this.router.navigate([this.config.returnUrl]);
      }
    }, (error) => {
      this.toastr.warning('Unable to find the item');
      this.router.navigate([this.config.returnUrl]);
    });
  }

  createFormGroup() {
    if (this.config.controls && this.config.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.config.controls.length; i++) {
        const column: FormField[] = this.config.controls[i];
        for (let j = 0; j < column.length; j++) {
          const control: FormField = column[j];
          let validators: any[] = [];
          if (control.required) {
            validators.push(Validators.required);
          }
          if (control.type === FormFieldType.Email) {
            validators.push(Validators.email);
          }
          let data: any;
          if (this.update && this.data[control.label]) {
            if (control.type === FormFieldType.DateTime) {
              data = this.utility.convertStringToDateObj(this.data[control.label]);
            } else {
              if (control.mutiple) {
                data = this.data[control.label].split(',');
              } else {
                data = this.data[control.label];
              }
            }
          }
          group[control.label] = new FormControl(data, validators);
        }
      }
      this.addItemForm = this.fb.group(group);
      this.ready = true;
    }
  }

  changeControl(label: string) {
    if (typeof label === 'string') {
      this.change.emit({
        control: label,
        data: this.formControls[label].value
      });
    }
  }

  saveItem() {
    if (this.addItemForm.valid) {
      let data: any = this.utility.copyObj(this.addItemForm.value);
      this.config.controls.forEach((column: FormField[]) => {
        column.forEach((control: FormField) => {
          if (control.type === FormFieldType.DateTime && data[control.label]) {
            data[control.label] = this.utility.convertDateObjToString(data[control.label]);
          } else if (control.type === FormFieldType.Number) {
            data[control.label] = +data[control.label];
          } else if (control.type === FormFieldType.Checkbox) {
            data[control.label] = +data[control.label] ? true : false;
          } else if (control.type === FormFieldType.DropDown && control.mutiple) {
            data[control.label] = data[control.label] ? data[control.label].join(',') : null;
          }
        });
      });
      if (!this.update) {
        if (this.autoGeneratedCode && !data[this.config.idProp]) {
          data[this.config.idProp] = '0';
        }
        this.restService.post(this.config.saveApiUrl, data).subscribe((response: any) => {
          if (response.status) {
            this.update = true;
          } else {
            this.toastr.error('Failed to add item!');
          }
        }, (error) => {
          this.toastr.error('Failed to add item!');
        });
      } else {
        if (!data[this.config.idProp]) {
          data[this.config.idProp] = this.data[this.config.idProp];
        }
        this.restService.post(this.config.updateApiUrl, data).subscribe((response: any) => {
          if (response.status) {
          } else {
            this.toastr.error('Failed to update item!');
          }
        }, (error) => {
          this.toastr.error('Failed to update item!');
        });
      }
    }
  }

  editItem() {
    this.viewMode = false;
    this.actionsConfig.showEdit = this.viewMode;
  }

  reset() {
    if (this.config.dropdownView) {
      this.back.emit(this.addItemForm.value);
    } else {
      this.router.navigate([this.config.returnUrl]);
    }
  }

  resetPassword() {
    this.restService.post('/Users/ResetPassword?email=' + this.data.email, {}).subscribe((response: any) => {
      if (response.status) {
        this.toastr.success('Password reset done!');
      } else {
        this.toastr.error('Failed to reset password!');
      }
    }, (error) => {
      this.toastr.error('Failed to reset password!');
    });
  }
}
