import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RestService } from 'src/app/Shared/services/rest.service';
import { SessionService } from 'src/app/Shared/services/session.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html'
})
export class ChangePasswordModalComponent implements OnInit {

  changePasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private restService: RestService) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      oldPassword: new FormControl(null, [Validators.required]),
      newPassword: new FormControl(null, [Validators.required]),
      confirmNewPassword: new FormControl(null, [Validators.required]),
    });
  }

  get oldPassword() { return this.changePasswordForm.controls.oldPassword; }
  get newPassword() { return this.changePasswordForm.controls.newPassword; }
  get confirmNewPassword() { return this.changePasswordForm.controls.confirmNewPassword; }

  changePassword() {
    if (this.changePasswordForm.valid) {
      const params = '&&oldPassword=' + this.oldPassword.value + '&&newPassword=' + this.newPassword.value;
      this.restService.post('/Users/ChangePassword?email=' + this.sessionService.UserName + params, {}).subscribe((response: any) => {
        if (response.status) {
          this.toastr.success('Password chagned successfully!');
          this.activeModal.dismiss();
        } else {
          this.toastr.error('Failed to change password!');
        }
      }, (error) => {
        this.toastr.error('Failed to change password!');
      });
    }
  }
}
