import { Component, HostListener, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../theme-options';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuGroup } from '../models/menuGroup';
import { MenuItems } from '../menu-items';
import { SessionService } from 'src/app/Shared/services/session.service';
import { RestService } from 'src/app/Shared/services/rest.service';
import { DataTableService } from 'src/app/Shared/services/data-table.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;

  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private dataTableService: DataTableService,
    private restService: RestService) {
  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboards';
  public menuItems: MenuGroup[] = [];
  private user: any;
  private userRole: any;
  private permissions: any[] = [];

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }


  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;
    if (this.sessionService.UserName === 'admin@tecsa.com.my') {
      this.menuItems = MenuItems;
    } else {
      this.getUserDetails();
    }
  }

  getUserDetails() {
    this.restService.get('/Users/GetByEmail?email=' + this.sessionService.UserName)
      .subscribe((response: any) => {
        if (response && response.status) {
          this.user = response.model;
          if (this.user.userrole === 'SuperAdmin') {
            this.menuItems = MenuItems;
            this.sessionService.SuperAdmin = true;
            this.sessionService.permissionsLoaded$.next();
          } else {
            this.getUserRoleDetails(this.user.userrole);
          }
        }
      });
  }

  getUserRoleDetails(roleID: string) {
    this.restService.get('/UserRole/GetByCode?code=' + roleID)
      .subscribe((response: any) => {
        if (response && response.status) {
          this.userRole = response.model;
          if (this.userRole.isSuperAdmin) {
            this.menuItems = MenuItems;
            this.sessionService.SuperAdmin = true;
            this.sessionService.permissionsLoaded$.next();
          } else {
            this.getRolePermissions(roleID);
          }
        }
      });
  }

  getRolePermissions(roleID: string) {
    this.restService.get('/Permission/GetByRoleID?roleID=' + roleID)
      .subscribe((response: any) => {
        if (response && response.status) {
          this.permissions = response.model;
          this.sessionService.Permissions = this.permissions;
          this.sessionService.permissionsLoaded$.next();
          this.updateMenuItems();
        }
      });
  }

  updateMenuItems() {
    let resultMenuItems: MenuGroup[] = [];
    const menuItems = MenuItems;
    for (let i = 0; i < menuItems.length; i++) {
      const item = menuItems[i];
      let childrens: any[] = [];
      for (let j = 0; j < item.menu.childrens.length; j++) {
        const children = item.menu.childrens[j];
        const permission = this.permissions.filter(p => p.objectName === children.title)[0];
        if (permission) {
          childrens.push(children);
        }
      }

      if (childrens.length > 0) {
        resultMenuItems.push({
          title: item.title,
          menu: {
            icon: item.menu.icon,
            title: item.menu.title,
            childrens: childrens
          }
        });
      }
    }
    this.menuItems = resultMenuItems;
  }

  navigativeUrl(url: string) {
    this.dataTableService.clearCacheData();
    this.router.navigate([url]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
}
