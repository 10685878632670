import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DataTableService } from '../services/data-table.service';
import { SortEvent } from '../../DemoPages/Tables/dynamic/demo/sortable.directive';
import { DataTableConfig } from '../models/dataTableConfig';
import { ActionsConfig } from '../models/actionsConfig';
import { RestService } from '../services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SortableHeaderDirective } from '../Directives/sortable-header.directive';
import { __values } from 'tslib';
import * as xlsx from 'xlsx';
import { ExportPdfService } from '../services/export-pdf.service';
import { SessionService } from '../services/session.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { Utility } from 'src/app/Shared/services/utility.service';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnDestroy {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  records: any[] = [];
  data$: Observable<any[]>;
  total$: Observable<number>;
  allData: any[] = [];
  allData1: any[] = [];
  hrefUrl: string = '';
  postedpurchase_present: number = 0;
  postedstockadjusment_present: number = 0;
  transfertovan_present: number = 0;
  transfertovan_warehouse: number = 0;
  transfertodailyoperation_present: number = 0;
  useraccesscontrol_present: number = 0;
  loading: boolean;
  totaldataCount: number = 0;

  actionConfig: ActionsConfig = {
    showView: true,
    showDelete: true,
    showSearch: true,
    showexportExcel: true,
    showExportPdf: true
  };
  protected permissionsLoadedSubscription: Subscription;

  @Input() config: DataTableConfig;
  @Input() set pageSize(value: number) {
    this.service.pageSize = value;
  }
  @Input() dropdown: boolean = false;
  @Output('add') addRow = new EventEmitter();
  @Output('edit') editRow = new EventEmitter<any>();

  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective>;

  constructor(
    private router: Router,
    private restService: RestService,
    private toastr: ToastrService,
    public service: DataTableService,
    private exportPdfService: ExportPdfService,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private utility: Utility) {
    service.pageSize = 25;
    service.pagination = true;
    this.data$ = service.data$;
    this.total$ = service.total$;
  }

  ngOnInit(): void {
    if (this.config && this.config.enableCache === false) {
    } else {
      this.config.enableCache = false;
    }

    this.getItems();

    this.hrefUrl = this.router.url;
    this.postedpurchase_present = this.hrefUrl.split('/').indexOf('postedpurchase');
    this.transfertovan_present = this.hrefUrl.split('/').indexOf('transfertovan');
    this.transfertovan_warehouse = this.hrefUrl.split('/').indexOf('transfertowarehouse');
    this.transfertodailyoperation_present = this.hrefUrl.split('/').indexOf('transfertodailyoperation');
    this.postedstockadjusment_present = this.hrefUrl.split('/').indexOf('postedstockadjusment');
    this.useraccesscontrol_present = this.hrefUrl.split('/').indexOf('useraccesscontrol');
    if (this.hrefUrl.split('/').indexOf('postedpurchase') !== -1) {
      this.actionConfig = {
        showCreate: false,
        showEdit: false,
        showView: true,
        showDelete: false,
        showSearch: true,
        showexportExcel: true,
        showExportPdf: true
      };
    } else if (this.hrefUrl.split('/').indexOf('postedstockadjusment') !== -1) {
      this.actionConfig = {
        showCreate: false,
        showEdit: false,
        showView: true,
        showDelete: false,
        showSearch: true,
        showexportExcel: true,
        showExportPdf: true
      };
    } else if (this.hrefUrl.split('/').indexOf('itemledgerstockadjusment') !== -1) {
      this.actionConfig = {
        showCreate: false,
        showEdit: false,
        showView: false,
        showDelete: false,
        showSearch: true,
        showexportExcel: true,
        showExportPdf: true
      };
    } else if (this.hrefUrl.split('/').indexOf('postedPurchaseReturnOrder') !== -1) {
      this.actionConfig = {
        showCreate: false,
        showEdit: false,
        showView: true,
        showDelete: false,
        showSearch: true,
        showexportExcel: true,
        showExportPdf: true
      };
    } else if (this.hrefUrl.split('/').indexOf('transfershippingorder') !== -1
      || this.hrefUrl.split('/').indexOf('transferreceiptorder') !== -1
      || this.transfertodailyoperation_present !== -1 || this.hrefUrl.split('/').indexOf('PostedvcSalesOrder') !== -1
      || this.hrefUrl.split('/').indexOf('saleshipment') !== -1 || this.hrefUrl.split('/').indexOf('salesinvoice') !== -1
      || this.hrefUrl.split('/').indexOf('postedphysicalstockadjusment') !== -1 || this.hrefUrl.split('/').indexOf('postedresetcounter') !== -1
      || this.hrefUrl.split('/').indexOf('postedcollectionjournal') !== -1) {
      this.actionConfig = {
        showCreate: false,
        showEdit: false,
        showView: true,
        showDelete: false,
        showSearch: true,
        showexportExcel: true,
        showExportPdf: true
      };
    } else {
      this.actionConfig = {
        showCreate: true,
        showEdit: true,
        showView: true,
        showDelete: true,
        showSearch: true,
        showexportExcel: true,
        showExportPdf: true
      };
    }

    this.actionConfig.showView = !this.dropdown;
    if (this.config && this.config.showButtons === false) {
      this.actionConfig.showCreate = false;
      this.actionConfig.showDelete = false;
      this.actionConfig.showEdit = false;
    }

    if (this.config && this.config.showSearch === false) {
      this.actionConfig.showSearch = false;
    }

    if (this.config && this.config.showCreate === false) {
      this.actionConfig.showCreate = false;
    }

    if (this.config && this.config.showView === false) {
      this.actionConfig.showView = false;
    }

    if (this.config && this.config.showEdit === false) {
      this.actionConfig.showEdit = false;
    }

    if (this.config && this.config.showDelete === false) {
      this.actionConfig.showDelete = false;
    }

    this.actionConfig.showPrint = this.config.showPrint;

    if (this.sessionService.SuperAdmin || this.sessionService.Permissions.length > 0) {
      const permission = this.sessionService.getPermission(this.config.pageName);
      this.actionConfig.showDelete = this.actionConfig.showDelete ? permission.delete : false;
      this.actionConfig.showEdit = this.actionConfig.showEdit ? permission.edit : false;
      this.actionConfig.showCreate = this.actionConfig.showCreate ? permission.create : false;
      this.actionConfig.showView = this.actionConfig.showView ? permission.read : false;
      this.actionConfig.showPrint = this.actionConfig.showPrint ? permission.read : false;
    } else {
      this.permissionsLoadedSubscription = this.sessionService.permissionsLoaded$.subscribe(() => {
        const permission = this.sessionService.getPermission(this.config.pageName);
        this.actionConfig.showDelete = this.actionConfig.showDelete ? permission.delete : false;
        this.actionConfig.showEdit = this.actionConfig.showEdit ? permission.edit : false;
        this.actionConfig.showCreate = this.actionConfig.showCreate ? permission.create : false;
        this.actionConfig.showView = this.actionConfig.showView ? permission.read : false;
        this.actionConfig.showPrint = this.actionConfig.showPrint ? permission.read : false;
      });
    }
  }

  updateItemsDisplayFormat(data: any[]) {
    if (this.config.displayFormat) {
      data.forEach((d: any) => {
        this.config.displayFormat.forEach((f: any) => {
          if (d[f.prop] === f.value) {
            d[f.prop] = f.display;
          }
        });
      });
    }
  }

  getItems() {
    const cacheData = this.service.getCacheData(this.config.title);
    this.service.headers = this.config.headers;
    this.service.data = [];
    this.service.searchTerm = '';
    if (cacheData && this.config.enableCache) {
      this.records = cacheData;
      this.service.data = cacheData;
      this.allData = cacheData;
    } else {
      this.loading = true;
      this.restService.get(this.config.getApiUrl).subscribe((data: any) => {
        if (data && Array.isArray(data)) {
          this.updateItemsDisplayFormat(data);
          this.records = data;
          this.allData = data;
          this.service.data = this.records;
        } else if (data.model) {
          this.updateItemsDisplayFormat(data.model);
          this.records = data.model;
          this.service.data = this.records;
          this.allData = data.model;
        }
        this.totaldataCount = this.records.length;
        this.service.setCacheData(this.config.title, this.allData);
        this.loading = false;
      }, (error) => {
        this.records = [];
        this.service.data = this.records;
        this.loading = false;
      });
    }
  }

  selectRow(record: any) {
    if (this.config.selctionType === 'single') {
      if (record.selected === undefined || record.selected === false) {
        this.records.map(d => d.selected = false);
        record.selected = true;
      } else if (record.selected) {
        this.records.map(d => d.selected = false);
        record.selected = false;
      }
    } else {
      if (record.selected === undefined) {
        record.selected = true;
      } else {
        record.selected = !record.selected;
      }
    }

    const selectedRecords: any[] = this.records.filter(d => d.selected);
    this.service.ItemSelected$.next(selectedRecords);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  isDeleteconditionSatisfied(data: any): boolean {
    if (this.config.deleteNotCondition && this.config.deleteNotCondition.length > 0) {
      for (let i = 0; i < this.config.deleteNotCondition.length; i++) {
        if (data[this.config.deleteNotCondition[i].prop] === this.config.deleteNotCondition[i].value) {
          return false;
        }
      }
    }

    return true;
  }

  areItemsDeleteconditionSatisfied(items: any[]): boolean {
    for (let i = 0; i < items.length; i++) {
      if (!this.isDeleteconditionSatisfied(items[i])) return false;
    }

    return true;
  }

  deleteItem(items: any[]) {
    if (this.hrefUrl.split('/').indexOf('route') !== -1 && this.hrefUrl.split('/').indexOf('plans') !== -1) {
      //alert("hiii");
      //console.log(items[0].visited);
      if (items.length === 1) {
        if (this.isDeleteconditionSatisfied(items[0])) {
          if (items[0].visited == "Not Visited") {
            items[0].visited = false;
            this.restService.post(this.config.deleteApiUrl, items[0]).subscribe((response: any) => {
              if (response.status) {
                this.records = this.records.filter((p: any) => p["lineNo"] !== items[0]["lineNo"]);
                this.service.ItemSelected$.next([]);
                this.service.headers = this.config.headers;
                this.service.data = this.records;
                this.toastr.success('Deleted successfully!');
              } else {
                this.toastr.error(response.message);
              }
            }, (error) => {
              this.toastr.error('Deleted failed!');
            });
          }
          else {
            this.toastr.error('The location ' + items[0].location + ' has already been visited against route plan code ' + items[0].routePlanCode + ' and assigned date ' + this.datePipe.transform(items[0].assignDate, 'MMMM d, y'));
          }
        }
        else {
          this.toastr.warning('This item can\'t be deleted!');
        }
      }
      else {

      }
    }


    else {
      if (items.length === 1) {
        if (this.isDeleteconditionSatisfied(items[0])) {
          this.restService.post(this.config.deleteApiUrl, items[0]).subscribe((response: any) => {
            if (response.status) {
              // this.records = this.records.filter((p: any) => p[this.config.idProp] !== items[0][this.config.idProp]);
              // this.service.ItemSelected$.next([]);
              // this.service.headers = this.config.headers;
              // this.service.data = this.records;
              if (this.config.lineGetByCode) {
                let linedata = [];
                this.restService.get(this.config.lineGetByCode + items[0][this.config.idProp]).subscribe((responseline: any) => {
                  if (responseline.model) {
                    linedata = responseline.model;
                  }
                  this.restService.post(this.config.lineDeleteUrl, linedata).subscribe((responsedlt: any) => {
                    this.toastr.success('Deleted successfully!');
                    if (this.config.isGeoLocation) {
                      this.records = this.records.filter((p: any) => p.latitude !== items[0].latitude && p.longitude !== items[0].longitude);
                    } else {
                      this.records = this.records.filter((p: any) => p[this.config.idProp] !== items[0][this.config.idProp]);
                    }
                    this.service.ItemSelected$.next([]);
                    this.service.headers = this.config.headers;
                    this.service.data = this.records;
                    //this.totaldataCount = this.records.length;
                  })
                })
              }
              else {
                this.toastr.success('Deleted successfully!');
                if (this.config.isGeoLocation) {
                  this.records = this.records.filter((p: any) => p.latitude !== items[0].latitude && p.longitude !== items[0].longitude);
                } else {
                  this.records = this.records.filter((p: any) => p[this.config.idProp] !== items[0][this.config.idProp]);
                }
                this.service.ItemSelected$.next([]);
                this.service.headers = this.config.headers;
                this.service.data = this.records;
                this.totaldataCount = this.records.length;
              }
              //this.toastr.success('Deleted successfully!');
            } else {
              this.toastr.error(response.message);
            }
          }, (error) => {
            this.toastr.error('Deleted failed!');
          });
        } else {
          this.toastr.warning('This item can\'t be deleted!');
        }
      } else if (items.length > 1) {
        if (this.areItemsDeleteconditionSatisfied(items)) {
          this.restService.post(this.config.multipleDeleteApiUrl, items).subscribe((response: any) => {
            if (response.status) {
              // const ids = items.map(i => i[this.config.idProp]);
              // this.records = this.records.filter((p: any) => !ids.includes(p[this.config.idProp]));
              // this.service.ItemSelected$.next([]);
              // this.service.headers = this.config.headers;
              // this.service.data = this.records;
              if (this.config.lineGetByCode) {
                let forkjoinarr = [];
                let deletedarrray = [];
                for (var i = 0; i < items.length; i++) {
                  var api = this.restService.get(this.config.lineGetByCode + items[i][this.config.idProp]);
                  forkjoinarr.push(api)
                }
                forkJoin(forkjoinarr).subscribe((results: any[]) => {
                  for (var j = 0; j < results.length; j++) {
                    if (results[j].model) {
                      for (var k = 0; k < results[j].model.length; k++) {
                        deletedarrray.push(this.utility.copyObj(results[j].model[k]));
                      }
                    }
                  }
                  //console.log(deletedarrray);
                  this.restService.post(this.config.lineDeleteUrl, deletedarrray).subscribe((responsedlt: any) => {
                    this.toastr.success('Deleted successfully!');
                    const ids = items.map(i => i[this.config.idProp]);
                    if (this.config.isGeoLocation) {
                      this.records = this.records.filter((p: any) => items.filter(x => x.latitude === p.latitude && x.longitude === p.longitude)[0] ? false : true);
                    } else {
                      this.records = this.records.filter((p: any) => !ids.includes(p[this.config.idProp]));
                    }
                    this.service.ItemSelected$.next([]);
                    this.service.headers = this.config.headers;
                    this.service.data = this.records;
                  })
                });
              }
              else {
                this.toastr.success('Deleted successfully!');
                const ids = items.map(i => i[this.config.idProp]);
                if (this.config.isGeoLocation) {
                  this.records = this.records.filter((p: any) => items.filter(x => x.latitude === p.latitude && x.longitude === p.longitude)[0] ? false : true);
                } else {
                  this.records = this.records.filter((p: any) => !ids.includes(p[this.config.idProp]));
                }

                this.service.ItemSelected$.next([]);
                this.service.headers = this.config.headers;
                this.service.data = this.records;
              }
            } else {
              this.toastr.error(response.message);
            }
          }, (error) => {
            this.toastr.error('Delete failed!');
          });
        } else {
          this.toastr.warning('These items can\'t be deleted!');
        }
      }
    }
  }

  addItem() {
    if (this.dropdown) {
      this.addRow.emit();
    } else {
      this.router.navigate([this.config.createUrl + '/add']);
    }
  }

  viewItem(item: any) {
    if (this.config.createUrl) {
      this.router.navigate([this.config.createUrl + '/view/' + item[this.config.idProp]]);
    }
  }

  editItem(item: any) {
    if (this.dropdown) {
      this.editRow.emit(item);
    } else {
      this.router.navigate([this.config.createUrl + '/' + item[this.config.idProp]]);
    }
  }

  search(searchText: string) {
    this.service.searchTerm = searchText;
  }

  Exportexcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.config.title + '.xlsx');
  }

  exportPdf() {
    this.exportPdfService.exportTableToPdf(this.config.headers, this.service.data, this.config.title);
  }

  isValidDate(value: string) {
    const regex = new RegExp('[a-su-zA-SU-Z]*');
    if (regex.test(value)) {
      return false;
    } else {
      return true;
    }
  }

  isDataTimeData(data: string) {
    const date = new Date(data);
    if (typeof data === 'string' && !isNaN(date.getTime()) && data.includes('-') && data.includes(':')) {
      return true;
    } else {
      return false;
    }
  }

  printReport() {
    const report = this.config.report;
    if (report) {
      const url = environment.report + report.page + '?ReportName=' + report.value
        + '&ReportDescription=' + report.name + '&width=100&height=650';
      window.open(url, '_blank');
    } else {
      this.toastr.warning('Please configure Report!');
    }
  }

  ngOnDestroy() {
    this.permissionsLoadedSubscription ? this.permissionsLoadedSubscription.unsubscribe() : null;
  }
}
