import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { LineDataConfig } from '../models/lineDataConfig';
import { RestService } from '../services/rest.service';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { FormFieldType } from '../models/formField.enum';
import { FormField } from '../models/formField';
import { EventDataModel } from '../models/eventDataModel';
import { FormDataService } from '../services/form-data.service';
import { Utility } from '../services/utility.service';
import { ActionsConfig } from '../models/actionsConfig';
import { CustomButton } from '../models/customButton';
import { LineCalculationConfig } from '../models/lineCalculationConfig';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataTableService } from '../services/data-table.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'line-datamaster',
  templateUrl: './line-datamaster.component.html',
  styleUrls: ['./line-datamaster.component.scss']
})
export class LineDataMasterComponent implements OnInit, OnDestroy {

  data: any[];
  ready: boolean = false;
  lineDataForm: FormGroup;
  postedstockadjusment_present: number = 0;
  present_viewpage: number = 0;
  hrefUrl: string = '';
  present_postedpurchase: number = 0;
  present_postedPurchaseReturnOrder: number = 0;
  actionsConfig: ActionsConfig = {
    showRight: true
  };
  viewMode: boolean = false;
  disableDeleteRowIndexes: number[] = [];
  disableDeleteButtons: boolean;
  searchLineKey: string;
  headerProps: string[] = [];
  data$: Observable<any[]>;
  total$: Observable<number>;
  loading: boolean;
  pageChanged: boolean;
  protected updateLineControlDataSubscription: Subscription;
  protected updateLineControlsListDataSubscription: Subscription;
  protected disableLineDeleteSubscription: Subscription;
  protected disableLinesDeleteSubscription: Subscription;
  protected disableAllLinesDeleteSubscription: Subscription;

  @Input() disable: boolean;
  @Input() disableAddButton: boolean;
  @Input() config: LineDataConfig;
  @Input() calculationConfig: LineCalculationConfig;
  @Input('buttons') customButtons: CustomButton[] = [];

  @Output() change = new EventEmitter<EventDataModel>();
  @Output() leave = new EventEmitter<any>();
  @Output() changeCalculation = new EventEmitter<EventDataModel>();
  @Output() delete = new EventEmitter<number>();
  @Output() calculationData = new EventEmitter<any>();
  @Output() customButtonClick = new EventEmitter<string>();
  @Output() pageData = new EventEmitter<any[]>();

  get items() { return this.lineDataForm.controls.items as FormArray; }

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private formDataService: FormDataService,
    private utility: Utility,
    private toastr: ToastrService,
    private router: Router,
    public service: DataTableService) {
    service.pageSize = 20;
    this.data$ = service.data$;
    this.total$ = service.total$;
  }

  ngOnInit() {
    this.formDataService.ResetDropDownApiDataCache();
    if (this.calculationConfig && this.calculationConfig.controls) {
      let group: any = {};
      for (let i = 0; i < this.calculationConfig.controls.length; i++) {
        const column: FormField[] = this.calculationConfig.controls[i];
        for (let j = 0; j < column.length; j++) {
          const control: FormField = column[j];
          let validators: any[] = [];
          if (control.required) {
            validators.push(Validators.required);
          }
          if (control.type === FormFieldType.Email) {
            validators.push(Validators.email);
          }

          group[control.label] = new FormControl(null, validators);
        }
      }
      group.items = new FormArray([]);
      this.lineDataForm = this.fb.group(group);
    } else {
      this.lineDataForm = this.fb.group({
        items: new FormArray([])
      });
    }

    this.headerProps = this.config.controls.map(c => c.label);
    this.service.headers = this.config.controls.map(c => {
      return { name: c.name, prop: c.label };
    });
    this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
      const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
      if (viewUrl.length > 0) {
        this.viewMode = true;
        this.hrefUrl = this.router.url;
        if (this.hrefUrl.split('/').indexOf('postedstockadjusment') !== -1
          || this.hrefUrl.split('/').indexOf('postedpurchase') !== -1
          || this.hrefUrl.split('/').indexOf('postedPurchaseReturnOrder') !== -1
          || this.hrefUrl.split('/').indexOf('transfershippingorder') !== -1
          || this.hrefUrl.split('/').indexOf('transferreceiptorder') !== -1
          || this.hrefUrl.split('/').indexOf('transfertodailyoperation') !== -1) {
          this.actionsConfig.showEdit = false;
        } else {
          this.actionsConfig.showEdit = false;
        }
      }
    });

    this.getItems();

    this.updateLineControlDataSubscription = this.formDataService.updateLineControlData$.subscribe((data: EventDataModel) => {
      const itemGroup = this.items.controls[data.rowIndex] as FormGroup;
      if (itemGroup) {
        itemGroup.controls[data.control].setValue(data.data);
      }
    });

    this.updateLineControlsListDataSubscription = this.formDataService.updateLineControlsListData$.subscribe((items: EventDataModel[]) => {
      items.forEach((item: EventDataModel) => {
        const itemGroup = this.items.controls[item.rowIndex] as FormGroup;
        if (itemGroup) {
          itemGroup.controls[item.control].setValue(item.data);
        }
      });
    });

    this.disableLineDeleteSubscription = this.formDataService.disableLineDelete$.subscribe((row: number) => {
      this.disableDeleteRowIndexes = [row];
    });

    this.disableLinesDeleteSubscription = this.formDataService.disableLinesDelete$.subscribe((rows: number[]) => {
      this.disableDeleteRowIndexes = rows;
    });

    this.disableAllLinesDeleteSubscription = this.formDataService.disableAllLinesDelete$.subscribe(() => {
      this.disableDeleteButtons = true;
    });

    const ab = window.location.href;
    const url1 = ab.split('/');
    this.present_postedpurchase = url1.indexOf('postedpurchase');
    this.present_viewpage = url1.indexOf('view');
    this.present_postedPurchaseReturnOrder = url1.indexOf('postedPurchaseReturnOrder');

    this.service.data$.subscribe((data: any[]) => {
      this.generateItemsFormArray(data);
      if (this.pageChanged) {
        this.loading = false;
      }
      this.pageData.emit(data);
    });
  }

  getItems() {
    this.loading = true;
    this.service.data = [];
    this.service.searchTerm = '';
    this.restService.get(this.config.getApiUrl).subscribe((response: any) => {
      if (response.model) {
        this.data = response.model;
        this.formDataService.getLineItems$.next(this.data);
        this.service.data = this.data;
        if (response.model.length > 0) {
        } else {
          this.generateItemsFormArray([{}]);
        }
      } else {
        this.generateItemsFormArray([{}]);
      }
      this.loading = false;
    }, (error) => {
      this.generateItemsFormArray([{}]);
      this.loading = false;
    });
  }

  generateItemsFormArray(data: any[]) {
    this.items.controls = [];
    data.forEach((item: any) => {
      this.createItemFormGroup(item);
    });
    this.ready = true;
  }

  createItemFormGroup(item: any, first: boolean = false) {
    if (this.config.controls && this.config.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.config.controls.length; i++) {
        const control: FormField = this.config.controls[i];
        let validators: any[] = [];
        if (control.required) {
          validators.push(Validators.required);
        }
        if (control.type === FormFieldType.Email) {
          validators.push(Validators.email);
        }
        let data: any;
        if (control.type === FormFieldType.DateTime) {
          data = this.utility.convertStringToDateObj(item[control.label]);
        } else {
          data = item[control.label];
        }

        if ((data === null || data === undefined) && (control.initialValue !== null && control.initialValue !== undefined)) {
          data = control.initialValue;
        }
        group[control.label] = new FormControl(data, validators);
      }
      if (first) {
        this.items.insert(0, this.fb.group(group));
      } else {
        this.items.push(this.fb.group(group));
      }
    }
  }

  changeControl(label: string, index: number) {
    const itemGroup = this.items.controls[index] as FormGroup;
    this.change.emit({
      control: label,
      data: itemGroup.controls[label].value,
      rowIndex: index
    });
    this.data = this.items.value;
  }

  leaveControl(row: number, controlIndex: number) {
    let isLineHasDisabledControls: boolean = false;
    const itemGroup = this.items.controls[row] as FormGroup;
    for(let i = 0; i < this.config.controls.length; i++) {
      const control = itemGroup.get(this.config.controls[i].label) as FormControl;
      if (control.disabled) {
        isLineHasDisabledControls = true;
        break;
      }
    }

    if (!isLineHasDisabledControls) {
      this.leave.emit(this.items.value);
    }
  }

  changeLineCalculationControl(label: string) {
    if (typeof label === 'string') {
      this.change.emit({
        control: label,
        data: this.lineDataForm.controls[label].value
      });
    }
  }

  leaveCalculationControl() {
    let data = this.lineDataForm.value;
    delete data.items;
    this.calculationData.emit(data);
  }

  addLine() {
    this.createItemFormGroup({}, false);
    this.leave.emit(this.items.value);
    this.data = this.items.value;
  }

  deleteLine(row: number) {
    if (!this.disableDeleteRowIndexes.includes(row) && !this.disableDeleteButtons && !this.disable) {
      let lineRows = this.items.controls as FormGroup[];
      if (lineRows.length > 0) {
        const data = lineRows[row].value;
        lineRows.splice(row, 1);
        this.delete.emit(data);
        this.items.updateValueAndValidity();
        this.data = this.items.value;
      }
    }
  }

  searchLine() {
    this.service.searchTerm = this.searchLineKey;
  }

  customButtonEvent(label: string) {
    this.customButtonClick.emit(label);
  }

  pageChange(page: number) {
    this.loading = true;
    this.pageChanged = true;
  }

  ngOnDestroy() {
    this.updateLineControlDataSubscription ? this.updateLineControlDataSubscription.unsubscribe() : null;
    this.updateLineControlsListDataSubscription ? this.updateLineControlsListDataSubscription.unsubscribe() : null;
    this.disableLineDeleteSubscription ? this.disableLineDeleteSubscription.unsubscribe() : null;
    this.disableLinesDeleteSubscription ? this.disableLinesDeleteSubscription.unsubscribe() : null;
    this.disableAllLinesDeleteSubscription ? this.disableAllLinesDeleteSubscription.unsubscribe() : null;
  }
}
