import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionService } from './Shared/services/session.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {

  title = 'ArchitectUI - Angular 7 Bootstrap 4 & Material Design Admin Dashboard Template';

  private apiUrl: string = environment.api;

  constructor(
    private sessionService: SessionService,
    private cookie: CookieService,
    private httpclient: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {
    // window.onbeforeunload = () => {
    //   let username = this.sessionService.UserName;
    //   let token_cookie = this.cookie.get("token");
    //   this.httpclient.get(this.apiUrl + '/Users/Logout?email=' + username + '&token=' + token_cookie).subscribe((datalogout: any) => {
    //     this.sessionService.Token = null;
    //     this.sessionService.UserName = null;
    //     this.cookie.set("token", null);
    //     this.router.navigate(['/auth/login']);
    //   });
    // }
  }

  ngOnInit() {
    if (this.sessionService.Token) {
      const path = window.location.href.replace(window.location.origin, '').replace('#/', '');
      if (path === '/' || path.includes('/auth/login')) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate([path]);
      }
    }

    setInterval(() => {
      this.sessionService.reduceSessionTime();
      if (this.sessionService.SessionTime <= 0) {
        this.httpclient.get(this.apiUrl + '/Users/Logout?email=' + this.sessionService.UserName + '&token=' + this.sessionService.Token).subscribe((datalogout: any) => {
          this.toastr.error('Session expired!');
          this.sessionService.Token = null;
          this.sessionService.UserName = null;
          this.cookie.set("token", null);
          this.router.navigate(['/auth/login']);
        });
      }
    }, 1000);
  }
}
