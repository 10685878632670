import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../../../theme-options';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/Shared/services/session.service';
import { RestService } from 'src/app/Shared/services/rest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import {CookieService} from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
  private apiUrl: string = environment.api;

  user: any;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
    public globals: ThemeOptions,
    private modal: NgbModal,
    private router: Router,
    private sessionService: SessionService,
    private restService: RestService,
    private cookie:CookieService,
    private httpclient: HttpClient,
    ) {
  }

  ngOnInit() {
    this.getUserDetails();
  }

  getUserDetails() {
    this.restService.get('/Users/GetByEmail?email=' + this.sessionService.UserName)
      .subscribe((response: any) => {
        if (response && response.status) {
          this.user = response.model;
        }
      });
  }

  logOut() {
    let username = this.sessionService.UserName;
    //alert(username);
    this.httpclient.get(this.apiUrl+'/Users/Logout?email='+username+'&token='+this.sessionService.Token).subscribe((datalogout: any) => {
        ////console.log(datalogout);
        this.sessionService.Token = null;
        this.cookie.set("token", null);
        this.router.navigate(['/auth/login']);
    });
    
  }

  changePassword() {
    const modalRef = this.modal.open(ChangePasswordModalComponent, { size: 'md' });
  }

}
