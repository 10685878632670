import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const HcLoadLineConfig: FormField[] = [
  // {
  //   type: FormFieldType.Number,
  //   label: 'invoice',
  //   name: 'Invoice'
  // },
  // {
  //   type: FormFieldType.Number,
  //   label: 'sales',
  //   name: 'Sales'
  // },
  {
    type: FormFieldType.Number,
    label: 'transfer',
    name: 'Load'
  },
  {
    type: FormFieldType.Number,
    label: 'transferReturn',
    name: 'Recall'
  },
  {
    type: FormFieldType.TextBox,
    label: 'ownership',
    name: 'Ownership',
  }
  // {
  //   type: FormFieldType.Number,
  //   label: 'unitPrice',
  //   name: 'Unit Price'
  // },
  // {
  //   type: FormFieldType.Number,
  //   label: 'adjustment1',
  //   name: 'Adjustment'
  // },
  // {
  //   type: FormFieldType.Number,
  //   label: 'adjustment2',
  //   name: 'Adjustment'
  // },
  // {
  //   type: FormFieldType.Number,
  //   label: 'amount',
  //   name: 'Amount'
  // }
];
