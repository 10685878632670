import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const CounterAmendmentLineConfig: FormField[] = [
  {
    type: FormFieldType.TextBox,
    label: 'itemNo',
    name: 'Counter No',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'postedDocumentNo',
    name: 'Document No',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'currentCtr', 
    name: 'Current Ctr',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'lastCtr', 
    name: 'Last Ctr'
  },
  {
    type: FormFieldType.TextBox,
    label: 'field2',  
    name: 'VM Sub Category',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'currentQty', 
    name: 'Current QTY',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'currentAmount', 
    name: 'Current Amount',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'amendCtr',
    name: 'Amend Ctr',
  },
  {
    type: FormFieldType.Number,
    label: 'differences',
    name: 'Differences',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'amendedQty',
    name: 'Amend Qty',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'unitPrice',
    name: 'Unit Price',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'newAmount',
    name: 'New Amount',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'amountDifference',
    name: 'Amount Difference',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'lineNo',
    name: 'Line No',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'ownership',
    name: 'Ownership',
    readonly:true
  }
];

export const CounterAmendmentCalculationConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'totalAmountDifference',
    name: 'Total Amount Difference'
  },
  {
    type: FormFieldType.Number,
    label: 'sst',
    name: 'SST'
  },
  {
    type: FormFieldType.Number,
    label: 'totalInclSST',
    name: 'Total Incl. SST'
  }
];
