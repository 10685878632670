import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormField } from '../models/formField';

@Injectable({
  providedIn: 'root'
})
export class Utility {

  constructor() { }

  public getFromGroupData(formGroup: FormGroup, fieldColumns: FormField[][]) {
    let data = {};

    fieldColumns.forEach((column: FormField[]) => {
      column.forEach((field: FormField) => {
        const control = formGroup.controls[field.label] as FormControl;
        data[field.label] = control.value;
      });
    });

    return data;
  }

  public copyObj(data: any) {
    return JSON.parse(JSON.stringify(data));
  }

  public convertDateTimeToString(data: Date) {
    return data ? data.getFullYear() + '-' + this.pad(data.getMonth() + 1) + '-' + this.pad(data.getDate()) + 'T00:00:00.000Z' : '';
  }

  public convertDateObjToString(data: any) {
    if (typeof data === 'object') {
      return data ? data.year + '-' + this.pad(data.month) + '-' + this.pad(data.day) + 'T00:00:00.000Z' : '';
    } else {
      return data;
    }
  }

  public convertStringToDateObj(data: string) {
    if (data) {
      const date = new Date(data);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
  }

  public sleep(sec: number) {
    const now = new Date().getTime();
    while (new Date().getTime() < now + sec) { /* do nothing */ }
  }

  public search(records: any[], headers: string[], key: string) {
    return records.filter(x => this.matches(x, headers, key));
  }

  public pad(n: number): string {
    return (n < 10) ? '0' + n : '' + n;
  }

  private matches(data: any, headers: string[], term: string) {
    let result: boolean = false;
    if (term && term !== '') {
      for (let i = 0; i < headers.length; i++) {
        const headerprop: any = headers[i];
        if (typeof data[headerprop] === 'string') {
          result = data[headerprop].toLowerCase().includes(term.toLowerCase());
        } else if (typeof data[headerprop] === 'number') {
          result = (data[headerprop] + '').toLowerCase().includes(term.toLowerCase());
        }

        if (result) break;
      }
    } else {
      result = true;
    }

    return result;
  }

}
