import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { LineDataConfig } from '../models/lineDataConfig';
import { RestService } from '../services/rest.service';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { FormFieldType } from '../models/formField.enum';
import { FormField } from '../models/formField';
import { EventDataModel } from '../models/eventDataModel';
import { FormDataService } from '../services/form-data.service';
import { Utility } from '../services/utility.service';
import { ActionsConfig } from '../models/actionsConfig';
import { CustomButton } from '../models/customButton';
import { LineCalculationConfig } from '../models/lineCalculationConfig';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'line-data',
  templateUrl: './line-data.component.html',
  styleUrls: ['./line-data.component.scss']
})
export class LineDataComponent implements OnInit, OnDestroy {

  data: any[];
  ready: boolean = false;
  lineDataForm: FormGroup;
  postedstockadjusment_present: number = 0;
  present_viewpage: number = 0;
  hrefUrl: string = '';
  present_postedpurchase: number = 0;
  present_postedPurchaseReturnOrder: number = 0;
  actionsConfig: ActionsConfig = {
    showRight: true
  };
  viewMode: boolean = false;
  disableDeleteRowIndexes: number[] = [];
  disableDeleteButtons: boolean;
  searchLineKey: string;
  headerProps: string[] = [];
  protected updateLineControlDataSubscription: Subscription;
  protected updateLineControlsListDataSubscription: Subscription;
  protected disableLineDeleteSubscription: Subscription;
  protected disableLinesDeleteSubscription: Subscription;
  protected disableAllLinesDeleteSubscription: Subscription;

  @Input() disable: boolean;
  @Input() disableAddButton: boolean;
  @Input() config: LineDataConfig;
  @Input() calculationConfig: LineCalculationConfig;
  @Input('buttons') customButtons: CustomButton[] = [];

  @Output() change = new EventEmitter<EventDataModel>();
  @Output() leave = new EventEmitter<any>();
  @Output() delete = new EventEmitter<number>();
  @Output() changeCalculation = new EventEmitter<EventDataModel>();
  @Output() calculationData = new EventEmitter<any>();
  @Output() customButtonClick = new EventEmitter<string>();
  @Output() search = new EventEmitter<any[]>();

  get items() { return this.lineDataForm.controls.items as FormArray; }

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private formDataService: FormDataService,
    private utility: Utility,
    private router: Router) { }

  ngOnInit() {
    this.formDataService.ResetDropDownApiDataCache();
    if (this.calculationConfig && this.calculationConfig.controls) {
      let group: any = {};
      for (let i = 0; i < this.calculationConfig.controls.length; i++) {
        const column: FormField[] = this.calculationConfig.controls[i];
        for (let j = 0; j < column.length; j++) {
          const control: FormField = column[j];
          let validators: any[] = [];
          if (control.required) {
            validators.push(Validators.required);
          }
          if (control.type === FormFieldType.Email) {
            validators.push(Validators.email);
          }
          group[control.label] = new FormControl(null, validators);
        }
      }
      group.items = new FormArray([]);
      this.lineDataForm = this.fb.group(group);
    } else {
      this.lineDataForm = this.fb.group({
        items: new FormArray([])
      });
    }

    this.headerProps = this.config.controls.map(c => c.label);
    this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
      const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
      if (viewUrl.length > 0) {
        this.viewMode = true;
        this.hrefUrl = this.router.url;
        if (this.hrefUrl.split("/").indexOf("postedstockadjusment") != -1 || this.hrefUrl.split("/").indexOf("postedpurchase") != -1 || this.hrefUrl.split("/").indexOf("postedPurchaseReturnOrder") != -1
          || this.hrefUrl.split("/").indexOf("transfershippingorder") != -1 || this.hrefUrl.split("/").indexOf("transferreceiptorder") != -1
          || this.hrefUrl.split("/").indexOf("transfertodailyoperation") != -1) {
          this.actionsConfig.showEdit = false;
        }
        else {
          // this.actionsConfig.showEdit = this.viewMode;
          this.actionsConfig.showEdit = false;
        }
      }
    });

    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id) {
        if (params.id !== 'add') {
          this.getItem(params.id);
        } else {
          this.generateItemsFormArray([{}]);
        }
      }
    });

    this.updateLineControlDataSubscription = this.formDataService.updateLineControlData$.subscribe((data: EventDataModel) => {
      const itemGroup = this.items.controls[data.rowIndex] as FormGroup;
      if (itemGroup) {
        itemGroup.controls[data.control].setValue(data.data);
      }
    });

    this.updateLineControlsListDataSubscription = this.formDataService.updateLineControlsListData$.subscribe((items: EventDataModel[]) => {
      items.forEach((item: EventDataModel) => {
        const itemGroup = this.items.controls[item.rowIndex] as FormGroup;
        if (itemGroup) {
          itemGroup.controls[item.control].setValue(item.data);
        }
      });
    });

    this.disableLineDeleteSubscription = this.formDataService.disableLineDelete$.subscribe((row: number) => {
      this.disableDeleteRowIndexes = [row];
    });

    this.disableLinesDeleteSubscription = this.formDataService.disableLinesDelete$.subscribe((rows: number[]) => {
      this.disableDeleteRowIndexes = rows;
    });

    this.disableAllLinesDeleteSubscription = this.formDataService.disableAllLinesDelete$.subscribe(() => {
      this.disableDeleteButtons = true;
    });

    let ab = window.location.href;
    let url1 = ab.split("/");
    this.present_postedpurchase = url1.indexOf("postedpurchase");
    this.present_viewpage = url1.indexOf("view");
    this.present_postedPurchaseReturnOrder = url1.indexOf("postedPurchaseReturnOrder");

  }

  getItem(id: string) {
    this.restService.get(this.config.getApiUrl + '?' + this.config.idProp + '=' + id).subscribe((response: any) => {
      if (response.model) {
        this.data = response.model;
        if (response.model.length > 0) {
          //console.log("response every id : " + JSON.stringify(this.data));
          localStorage.setItem("databycode", JSON.stringify(this.data));
          this.generateItemsFormArray(this.data);
        } else {
          this.generateItemsFormArray([{}]);
        }
      } else {
        this.generateItemsFormArray([{}]);
      }
    }, (error) => {
      this.generateItemsFormArray([{}]);
    });
  }

  generateItemsFormArray(data: any[]) {
    data.forEach((item: any) => {
      this.createItemFormGroup(item);
    });
    this.ready = true;
  }

  createItemFormGroup(item: any, first: boolean = false) {
    if (this.config.controls && this.config.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.config.controls.length; i++) {
        const control: FormField = this.config.controls[i];
        let validators: any[] = [];
        if (control.required) {
          validators.push(Validators.required);
        }
        if (control.type === FormFieldType.Email) {
          validators.push(Validators.email);
        }
        let data: any;
        if (control.type === FormFieldType.DateTime) {
          data = this.utility.convertStringToDateObj(item[control.label]);
        } else {
          data = item[control.label];
        }
        group[control.label] = new FormControl(data, validators);
      }

      if (first) {
        this.items.insert(0, this.fb.group(group));
      } else {
        this.items.push(this.fb.group(group));
      }
    }
  }

  changeControl(label: string, index: number) {
    if (typeof label === 'string') {
      const itemGroup = this.items.controls[index] as FormGroup;
      //console.log(itemGroup.controls[label].value);
      this.change.emit({
        control: label,
        data: itemGroup.controls[label].value,
        rowIndex: index
      });
    }
  }

  leaveControl(row: number, controlIndex: number) {
    this.leave.emit(this.items.value);
  }

  changeLineCalculationControl(label: string) {
    if (typeof label === 'string') {
      this.change.emit({
        control: label,
        data: this.lineDataForm.controls[label].value
      });
    }
  }

  leaveCalculationControl() {
    let data = this.lineDataForm.value;
    delete data.items;
    this.calculationData.emit(data);
  }

  addLine() {
    this.createItemFormGroup({}, false);
    this.leave.emit(this.items.value);
  }

  deleteLine(row: number) {
    if (!this.disableDeleteRowIndexes.includes(row) && !this.disableDeleteButtons && !this.disable) {
      let lineRows = this.items.controls as FormGroup[];
      if (lineRows.length > 1) {
        const data = lineRows[row].value;
        lineRows.splice(row, 1);
        this.delete.emit(data);
        this.items.updateValueAndValidity();
      }
    }
  }

  searchLine() {
    this.items.controls = [];
    if (this.searchLineKey) {
      const result = this.utility.search(this.data, this.headerProps, this.searchLineKey);
      this.generateItemsFormArray(result);
      this.search.emit(result);
    } else {
      this.generateItemsFormArray(this.data);
      this.search.emit(this.data);
    }
  }

  customButtonEvent(label: string) {
    this.customButtonClick.emit(label);
  }

  ngOnDestroy() {
    this.updateLineControlDataSubscription ? this.updateLineControlDataSubscription.unsubscribe() : null;
    this.updateLineControlsListDataSubscription ? this.updateLineControlsListDataSubscription.unsubscribe() : null;
    this.disableLineDeleteSubscription ? this.disableLineDeleteSubscription.unsubscribe() : null;
    this.disableLinesDeleteSubscription ? this.disableLinesDeleteSubscription.unsubscribe() : null;
    this.disableAllLinesDeleteSubscription ? this.disableAllLinesDeleteSubscription.unsubscribe() : null;
  }
}
