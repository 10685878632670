import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormField } from '../models/formField';
import { FormFieldType } from '../models/formField.enum';
import { RestService } from '../services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { ActionsConfig } from '../models/actionsConfig';
import { HeaderDataConfig } from '../models/headerDataConfig';
import { FormDataModel } from '../models/formDataModel';
import { CustomButton } from '../models/customButton';
import { Utility } from '../services/utility.service';
import { FormDataService } from '../services/form-data.service';
import { SessionService } from '../services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'header-data',
  templateUrl: './header-data.component.html',
  styleUrls: ['./header-data.component.sass']
})
export class HeaderDataComponent implements OnInit, OnDestroy {
  hrefUrl: string = '';
  postedpurchase_present: number = 0;
  data: any;
  update: boolean = false;
  ready: boolean = false;
  viewMode: boolean = false;
  headerDataForm: FormGroup;
  actionsConfig: ActionsConfig = {
    showPost: true,
    showBack: true,
    showRight: true,
  };
  protected permissionsLoadedSubscription: Subscription;


  @Input() config: HeaderDataConfig;
  @Input('buttons') customButtons: CustomButton[] = [];
  @Output() change = new EventEmitter<any>();
  @Output() leave = new EventEmitter<FormDataModel>();
  @Output() post = new EventEmitter<FormDataModel>();
  @Output() reverse = new EventEmitter<FormDataModel>();
  @Output() undoshipment = new EventEmitter<FormDataModel>();
  @Output() customButtonClick = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private restService: RestService,
    private utility: Utility,
    private formDataService: FormDataService,
    private sessionService: SessionService) {
  }


  //hrefUrl = this.router.url;

  ngOnInit() {
    this.formDataService.ResetDropDownApiDataCache();

    this.hrefUrl = this.router.url;
    this.postedpurchase_present = this.hrefUrl.split("/").indexOf("postedpurchase");
    if (this.hrefUrl.split("/").indexOf("postedpurchase") != -1) {
      this.actionsConfig = {
        showPost: false,
        showBack: true,
        showRight: true,
        showReverse: true,
        ShowundoShipment: false
      };
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          this.actionsConfig.showEdit = false;
        }
      });
    }
    else if (this.hrefUrl.split("/").indexOf("transfershippingorder") != -1) {
      this.actionsConfig = {
        showPost: false,
        showBack: true,
        showRight: true,
        showReverse: false,
        ShowundoShipment: true
      };
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          this.actionsConfig.showEdit = false;
        }
      });
    }
    else if (this.hrefUrl.split("/").indexOf("postedPurchaseReturnOrder") != -1 || this.hrefUrl.split("/").indexOf("transferreceiptorder") != -1
      || this.hrefUrl.split("/").indexOf("transfertodailyoperation") != -1
      || this.hrefUrl.split("/").indexOf("PostedvcSalesOrder") != -1 || this.hrefUrl.split("/").indexOf("saleshipment") != -1
      || this.hrefUrl.split("/").indexOf("salesinvoice") != -1 || this.hrefUrl.split("/").indexOf("postedphysicalstockadjusment") != -1
      || this.hrefUrl.split("/").indexOf("postedresetcounter") != -1 || this.hrefUrl.split("/").indexOf("postedcollectionjournal") != -1
      || this.hrefUrl.split("/").indexOf("postedcounteramendment") != -1) {
      this.actionsConfig = {
        showPost: false,
        showBack: true,
        showRight: true,
        showReverse: false,
        ShowundoShipment: false
      };
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          this.actionsConfig.showEdit = false;
        }
      });
    }
    else if (this.hrefUrl.split("/").indexOf("vcSalesOrder") != -1) {
      this.actionsConfig = {
        showPost: true,
        showBack: true,
        showRight: true,
        showReverse: false,
        ShowundoShipment: false
      };
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          this.actionsConfig.showEdit = false;
        }
      });
    }
    else if (this.hrefUrl.split("/").indexOf("SetupInventory") != -1) {
      this.actionsConfig = {
        showPost: true,
        showBack: false,
        showRight: true,
        showReverse: false,
        ShowundoShipment: false
      };
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          this.actionsConfig.showEdit = false;
        }
      });
    }
    else if (this.hrefUrl.split("/").indexOf("inventoryMovementReport") != -1 || this.hrefUrl.split("/").indexOf("productivitysoireport") != -1 || this.hrefUrl.split("/").indexOf("ExtractPostedStockAdjustment") != -1) {
      this.actionsConfig = {
        showPost: false,
        showBack: false,
        showRight: true,
        showReverse: false,
        ShowundoShipment: false,
      };
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          this.actionsConfig.showEdit = false;
        }
      });
    }
    else {
      this.actionsConfig = {
        showPost: true,
        showBack: true,
        showRight: true,
        showReverse: false,
        ShowundoShipment: false
      };
      this.activatedRoute.url.subscribe((urls: UrlSegment[]) => {
        const viewUrl = urls.filter((u: UrlSegment) => u.path === 'view');
        if (viewUrl.length > 0) {
          this.viewMode = true;
          //this.actionsConfig.showEdit = this.viewMode;
          this.actionsConfig.showEdit = false;
        }
      });
    }

    if (this.sessionService.SuperAdmin || this.sessionService.Permissions.length > 0) {
      const permission = this.sessionService.getPermission(this.config.title);
      this.actionsConfig.showPost = this.actionsConfig.showPost ? permission.post : false;
    } else {
      this.permissionsLoadedSubscription = this.sessionService.permissionsLoaded$.subscribe(() => {
        const permission = this.sessionService.getPermission(this.config.title);
        this.actionsConfig.showPost = this.actionsConfig.showPost ? permission.post : false;
      });
    }

    this.activatedRoute.params.subscribe((params: any) => {
      if (this.hrefUrl.split("/").indexOf("inventoryMovementReport") != -1 || this.hrefUrl.split("/").indexOf("productivitysoireport") != -1 || this.hrefUrl.split("/").indexOf("ExtractPostedStockAdjustment") != -1) {
        this.createFormGroup();
      }
      else {
        if (params.id) {
          if (params.id !== 'add') {
            this.update = true;
            this.getItem(params.id);
          } else {
            this.createFormGroup();
          }
        } else {
          this.router.navigate([this.config.returnUrl]);
        }
      }
    });
  }

  get formControls() { return this.headerDataForm.controls; }

  getItem(id: string) {
    this.restService.get(this.config.getApiUrl + '?' + this.config.idProp + '=' + id).subscribe((response: any) => {
      if (response.model) {
        this.data = response.model;
        this.createFormGroup();
      } else {
        this.toastr.warning('Unable to find the header data.');
        this.router.navigate([this.config.returnUrl]);
      }
    }, (error) => {
      this.toastr.warning('Unable to find the header data.');
      this.router.navigate([this.config.returnUrl]);
    });
  }

  createFormGroup() {
    if (this.config.controls && this.config.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.config.controls.length; i++) {
        const column: FormField[] = this.config.controls[i];
        for (let j = 0; j < column.length; j++) {
          const control: FormField = column[j];
          let validators: any[] = [];
          if (control.required) {
            validators.push(Validators.required);
          }
          if (control.type === FormFieldType.Email) {
            validators.push(Validators.email);
          }
          let data: any;
          if (this.update) {
            if (control.type === FormFieldType.DateTime) {
              data = this.utility.convertStringToDateObj(this.data[control.label]);
            } else {
              data = this.data[control.label];
            }
          }
          group[control.label] = new FormControl(data, validators);
        }
      }
      this.headerDataForm = this.fb.group(group);
      //console.log(this.headerDataForm);
      this.ready = true;
    }
  }


  changeControl(label: string) {
    if (typeof label === 'string') {
      this.change.emit({
        control: label,
        data: this.formControls[label].value
      });
    }
  }

  leaveControl() {
    this.leave.emit({
      data: this.headerDataForm.value,
      valid: this.headerDataForm.valid
    });
  }

  postItem() {
    this.post.emit({
      data: this.headerDataForm.value,
      valid: this.headerDataForm.valid
    });
  }


  reverseItem() {
    this.reverse.emit({
      data: this.headerDataForm.value,
      valid: this.headerDataForm.valid
    });
  }

  undoShipmentItem() {
    this.undoshipment.emit({
      data: this.headerDataForm.value,
      valid: this.headerDataForm.valid
    });
  }

  editItem() {
    this.viewMode = false;
    this.actionsConfig.showEdit = this.viewMode;
  }

  reset() {
    this.router.navigate([this.config.returnUrl]);
  }

  customButtonEvent(label: string) {
    this.customButtonClick.emit(label);
  }

  ngOnDestroy() {
    this.permissionsLoadedSubscription ? this.permissionsLoadedSubscription.unsubscribe() : null;
  }
}
