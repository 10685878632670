import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionsConfig } from '../models/actionsConfig';
import { DataTableService } from '../services/data-table.service';
import { ToastrService } from 'ngx-toastr';
import { CustomButton } from '../models/customButton';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {

  searchTerm: string;
  private _selectedItems: any[] = [];

  @Input() config: ActionsConfig = {
    showCreate: true,
    showSearch: true,
    showEdit: true,
    showDelete: true,
    showexportExcel: true
  };
  @Input() viewMode: boolean;
  @Input('buttons') customButtons: CustomButton[] = [];

  @Output() search = new EventEmitter<string>();
  @Output() create = new EventEmitter();
  @Output() view = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() post = new EventEmitter();
  @Output() reverse = new EventEmitter();
  @Output() undoshipment = new EventEmitter();
  @Output() resetpassword = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() exportexcel = new EventEmitter<any[]>();
  @Output() exportpdf = new EventEmitter();
  @Output() print = new EventEmitter();
  @Output() customButtonClick = new EventEmitter<string>();

  constructor(
    private toastr: ToastrService,
    private dataTableService: DataTableService) { }

  ngOnInit(): void {
    this.dataTableService.ItemSelected$.subscribe((items: any[]) => {
      this._selectedItems = items;
    });
  }

  addItem() {
    this.create.emit();
  }

  viewItem() {
    if (this._selectedItems.length > 0) {
      this.view.emit(this._selectedItems[0]);
    } else {
      this.toastr.warning('Select item to view');
    }
  }

  editItem() {
    if (this.viewMode) {
      this.edit.emit(null);
    } else {
      if (this._selectedItems.length > 0) {
        this.edit.emit(this._selectedItems[0]);
      } else {
        this.toastr.warning('Select item to edit');
      }
    }
  }

  deleteItem() {
    if (this._selectedItems.length > 0) {
      this.delete.emit(this._selectedItems);
    } else {
      this.toastr.warning('Select item to delete');
    }
  }

  searchItem() {
    this.search.emit(this.searchTerm);
  }

  postData() {
    this.post.emit();
  }

  reverseData() {
    this.reverse.emit();
  }

  undoShipmentData() {
    this.undoshipment.emit();
  }

  goBack() {
    this.back.emit();
  }

  customButtonEvent(label: string) {
    this.customButtonClick.emit(label);
  }

  resetPassword() {
    this.resetpassword.emit();
  }

  exportCsv() {
    this.exportexcel.emit();
  }

  exportPdf() {
    this.exportpdf.emit();
  }

  emitPrint() {
    this.print.emit();
  }
}
