import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  private apiUrl: string = environment.api;
  get httpOptions() {
    return {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.sessionService.Token
        })
    };
  }

  constructor(private httpClient: HttpClient,
    private sessionService: SessionService) {
  }

  public get(endpoint: string) {
    return this.httpClient.get(this.apiUrl + endpoint, this.httpOptions);
  }

  public post(endpoint: string, body: any) {
    return this.httpClient.post(this.apiUrl + endpoint, body, this.httpOptions);
  }

  public put(endpoint: string, body: any) {
    return this.httpClient.put(this.apiUrl + endpoint, body, this.httpOptions);
  }

  public delete(endpoint: string) {
    return this.httpClient.delete(this.apiUrl + endpoint, this.httpOptions);
  }
}
