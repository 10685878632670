import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const StockCountLineConfig: FormField[] = [
  {
    type: FormFieldType.TextBox,
    label: 'itemNo',
    name: 'Item No',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'description',
    name: 'Description',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'unitofMeasureCode',
    name: 'Unit of Measure',
    readonly:true
  },
  // {
  //   type: FormFieldType.TextBox,
  //   label: 'locationCode',
  //   name: 'Location Code'
  // },
  {
    type: FormFieldType.Number,
    label: 'quantity',
    name: 'System Quantity',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'physicalQuantity',
    name: 'Physical Quantity'
  },
  {
    type: FormFieldType.Number,
    label: 'differences',
    name: 'Differences',
    readonly:true
  },
  // {
  //   type: FormFieldType.TextBox,
  //   label: 'documentNo',
  //   name: 'Document No'
  // },
  {
    type: FormFieldType.DropDown,
    label: 'reasonCode',
    name: 'Reason',
    apiUrl: '/Reason/GetAll',
    bindValue: 'code',
    displayFormat: '[code]-[description]',
  },
];

export const StockCountLineCalculationConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'totalExclSST',
    name: 'Total Excl. SST'
  },
  {
    type: FormFieldType.Number,
    label: 'sst',
    name: 'SST'
  },
  {
    type: FormFieldType.Number,
    label: 'totalInclSST',
    name: 'Total Incl. SST'
  }
];
