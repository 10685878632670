import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private _sessionTime = environment.sessionTimeout * 60;
  private apiUrl: string = environment.api;
  public permissionsLoaded$ = new Subject();

  constructor(
    private cookie: CookieService,
    private router: Router,
    private httpclient: HttpClient,
    private toastr: ToastrService
  ) {
  }

  public get Token(): string {
    return localStorage.getItem('token');
  }

  public get SessionTime(): number {
    return this._sessionTime;
  }

  public set Token(v: string) {
    this._sessionTime = environment.sessionTimeout * 60;
    localStorage.setItem('token', v)
  }

  public reduceSessionTime() {
    this._sessionTime--;
  }

  public get UserName(): string {
    return localStorage.getItem('username');
  }

  public set UserName(v: string) {
    localStorage.setItem('username', v);
  }

  private permissions: any[] = [];
  public get Permissions(): any[] {
    return this.permissions;
  }
  public set Permissions(v: any[]) {
    this.permissions = v;
  }

  private superAdmin: boolean;
  public get SuperAdmin(): boolean {
    return this.superAdmin;
  }
  public set SuperAdmin(v: boolean) {
    this.superAdmin = v;
  }

  public getPermission(pageName: string) {
    if (this.superAdmin) {
      return {
        read: true,
        create: true,
        edit: true,
        post: true,
        delete: true
      };
    } else {
      const permission = this.permissions.filter(p => p.objectName.includes(pageName))[0];
      if (permission) {
        return {
          read: permission.readPermission,
          create: permission.insertPermission,
          edit: permission.modifyPermission,
          post: permission.postPermission,
          delete: permission.deletePermission
        };
      } else {
        return {
          read: false,
          create: false,
          edit: false,
          post: false,
          delete: false
        };
      }
    }
  }
}
