import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const SalesLineConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'invoice',
    name: 'Sales'
  },
  {
    type: FormFieldType.Number,
    label: 'transfer',
    name: 'Load'
  },
  {
    type: FormFieldType.Number,
    label: 'adjustment',
    name: 'TEST(O)'
  },
  {
    type: FormFieldType.Number,
    label: 'transferReturn',
    name: 'Recall'
  },
  {
    type: FormFieldType.Number,
    label: 'unitPrice',
    name: 'Unit Price'
  },
  {
    type: FormFieldType.Number,
    label: 'amount',
    name: 'Amount'
  },
  {
    type: FormFieldType.TextBox,
    label: 'ownership',
    name: 'Ownership',
  }
];

export const SalesLineCalculationConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'totalExclSST',
    name: 'Total Excl. SST'
  },
  {
    type: FormFieldType.Number,
    label: 'sst',
    name: 'SST'
  },
  {
    type: FormFieldType.Number,
    label: 'totalInclSST',
    name: 'Total Incl. SST'
  },
  {
    type: FormFieldType.Number,
    label: 'maximumcapacity',
    name: 'Maximum Capacity'
  },
  {
    type: FormFieldType.Number,
    label: 'currentbal',
    name: 'Currrent Bal'
  }
];
