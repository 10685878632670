import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormField } from '../models/formField';
import { FormFieldType } from '../models/formField.enum';
import { RestService } from '../services/rest.service';
import { ToastrService } from 'ngx-toastr';
import { AddDropdownItemConfig } from '../models/addDropdownItemConfig';
import { Utility } from '../services/utility.service';

@Component({
  selector: 'add-dropdown-item',
  templateUrl: './add-dropdown-item.component.html',
  styleUrls: ['./add-dropdown-item.component.scss']
})
export class AddDropdownItemComponent implements OnInit {

  data: any;
  update: boolean = false;
  ready: boolean = false;
  addItemForm: FormGroup;

  @Input() config: AddDropdownItemConfig;
  @Output() save = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private restService: RestService,
    private utility: Utility) {
  }

  ngOnInit() {
    this.createFormGroup();
  }

  createFormGroup() {
    if (this.config.controls && this.config.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.config.controls.length; i++) {
        const column: FormField[] = this.config.controls[i];
        for (let j = 0; j < column.length; j++) {
          const control: FormField = column[j];
          let validators: any[] = [];
          if (control.required) {
            validators.push(Validators.required);
          }
          if (control.type === FormFieldType.Email) {
            validators.push(Validators.email);
          }

          let data: any;
          if (this.update) {
            if (control.type === FormFieldType.DateTime) {
              data = this.utility.convertStringToDateObj(this.data[control.label]);
            } else {
              data = this.data[control.label];
            }
          }
          group[control.label] = new FormControl(data, validators);
        }
      }
      this.addItemForm = this.fb.group(group);
      this.ready = true;
    }
  }

  saveItem() {
    if (this.addItemForm.valid) {
      let data: any = this.addItemForm.value;
      if (!this.update) {
        this.restService.post(this.config.saveApiUrl, data).subscribe((response: any) => {
          this.toastr.success('Item addedd successfully!');
          this.update = true;
          this.save.emit(data);
        }, (error) => {
          this.toastr.error('Failed to add item!');
        });
      } else {
        this.restService.post(this.config.updateApiUrl, data).subscribe((response: any) => {
          this.toastr.success('Item updated successfully!');
          this.save.emit(data);
        }, (error) => {
          this.toastr.error('Failed to update item!');
        });
      }
    }
  }

}
