import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormField } from '../models/formField';
import { FormFieldType } from '../models/formField.enum';
import { AbstractControl, FormGroup, NgFormSelectorWarning, ValidationErrors, Validators } from '@angular/forms';
import { RestService } from '../services/rest.service';
import { NgbModal, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { SelectDropdownItemModalComponent } from '../modals/select-dropdown-item-modal/select-dropdown-item-modal.component';
import { TargetField } from '../models/targetField';
import { FieldItemType } from '../models/fieldItemType';
import { FormFieldService } from '../services/form-field.service';
import { UpdateDropdownData } from '../models/updateDropdownData';
import { FormDataService } from '../services/form-data.service';
import { EventDataModel } from '../models/eventDataModel';
import { DropdownModalResult } from '../models/dropdownModalResult';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormFieldComponent implements OnInit, OnDestroy {

  items: any[] = [];
  ready: boolean = false;
  public fieldType = FormFieldType;
  @Input() rowIndex: number = 0;
  @Input() control: FormField;
  @Input() formGroup: FormGroup;
  @Input() tableView: boolean = false;
  @Input() update: boolean = false;
  @Input() set disable(value: boolean) {
    if (value || this.control.disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  @Output() change = new EventEmitter<string>();
  @Output() leave = new EventEmitter();
  protected dropdownApiCallDoneSubscription: Subscription;
  protected updateDropdownItemSubscription: Subscription;
  protected updateControlDataSubscription: Subscription;
  protected updateLineControlDataSubscription: Subscription;
  protected updateControlsListDataSubscription: Subscription;
  protected updateLineControlsListDataSubscription: Subscription;
  protected disableControlSubscription: Subscription;
  protected disableControlsListSubscription: Subscription;
  protected setControlValidatorsSubscription: Subscription;
  protected setControlListValidatorsSubscription: Subscription;
  protected disableLineControlSubscription: Subscription;
  protected disableLineControlsListSubscription: Subscription;
  protected setLineControlValidatorsSubscription: Subscription;
  protected setLineControlListValidatorsSubscription: Subscription;
  protected readonlyControlSubscription: Subscription;
  protected readonlyControlsListSubscription: Subscription;
  protected readonlyLineControlSubscription: Subscription;
  protected readonlyLineControlslistSubscription: Subscription;
  protected enableControlSubscription: Subscription;
  protected enableControlsListSubscription: Subscription;
  protected enableLineControlSubscription: Subscription;
  protected enableLineControlsListSubscription: Subscription;
  protected setDateTimeMinMaxDatesSubscription: Subscription;
  pictureFormat = 'image/x-png,image/gif,image/jpeg,image/webp,image/bmp';
  imageSource: string;

  constructor(
    private restService: RestService,
    private modal: NgbModal,
    private formFielService: FormFieldService,
    private formDataService: FormDataService,
    private datepickerConfig: NgbDatepickerConfig,
    private toastr: ToastrService) { }

  ngOnInit() {

    if (this.control.unique) {
      this.formControl.setAsyncValidators(this.isUniqueData.bind(this));
    }

    if (this.control.dataExists) {
      this.formControl.setAsyncValidators(this.isDataExists.bind(this));
    }

    this.items = this.control.items;
    this.updateDropdownItemSubscription = this.formFielService.updateDropdownItem$.subscribe((data: UpdateDropdownData) => {
      if ((data.rowIndex !== null && data.rowIndex !== undefined && data.label === this.control.label && this.rowIndex === data.rowIndex)
        || ((data.rowIndex === null || data.rowIndex === undefined) && data.label === this.control.label)) {
        this.items = data.items;
        this.control.bindLabel = data.bindLabel;
        this.control.bindValue = data.bindValue;
        this.formControl.setValue(null);
      }
    });

    this.updateControlDataSubscription = this.formDataService.updateControlData$.subscribe((data: EventDataModel) => {
      if (data.control === this.control.label) {
        this.formControl.setValue(data.data, { emitEvent: data.eventEmit });
        if (data.eventEmit) {
          this.changeControl();
        }
      }
    });

    this.updateLineControlDataSubscription = this.formDataService.updateLineControlData$.subscribe((data: EventDataModel) => {
      if (data.control === this.control.label && data.rowIndex === this.rowIndex) {
        this.formControl.setValue(data.data, { emitEvent: data.eventEmit });
        if (data.eventEmit) {
          this.changeControl();
        }
      }
    });

    this.updateControlsListDataSubscription = this.formDataService.updateControlsListData$.subscribe((items: EventDataModel[]) => {
      items.forEach((item: EventDataModel) => {
        if (item.control === this.control.label) {
          this.formControl.setValue(item.data, { emitEvent: item.eventEmit });
          if (item.eventEmit) {
            this.changeControl();
          }
        }
      });
    });

    this.updateLineControlsListDataSubscription = this.formDataService.updateLineControlsListData$.subscribe((items: EventDataModel[]) => {
      items.forEach((item: EventDataModel) => {
        if (item.control === this.control.label && item.rowIndex === this.rowIndex) {
          this.formControl.setValue(item.data, { emitEvent: item.eventEmit });
          if (item.eventEmit) {
            this.changeControl();
          }
        }
      });
    });

    this.disableControlSubscription = this.formDataService.disableControl$.subscribe((label: string) => {
      if (label === this.control.label) {
        this.formControl.disable();
      }
    });

    this.disableControlsListSubscription = this.formDataService.disableControlsList$.subscribe((labels: string[]) => {
      labels.forEach((label: string) => {
        if (label === this.control.label) {
          this.formControl.disable();
        }
      });
    });

    this.setControlValidatorsSubscription = this.formDataService.setControlValidators$.subscribe((data: { lablel: string, required: boolean }) => {
      if (data.lablel === this.control.label) {
        this.setControlValidators(data.required);
      }
    });

    this.setControlListValidatorsSubscription = this.formDataService.setControlListValidators$.subscribe((list: { lablel: string, required: boolean }[]) => {
      list.forEach((data: { lablel: string, required: boolean }) => {
        if (data.lablel === this.control.label) {
          this.setControlValidators(data.required);
        }
      });
    });

    this.disableLineControlSubscription = this.formDataService.disableLineControl$.subscribe((data: { label: string, rowIndex: number }) => {
      if (data.rowIndex === this.rowIndex && data.label === this.control.label) {
        this.formControl.disable();
      }
    });

    this.disableLineControlsListSubscription = this.formDataService.disableLineControlsList$.subscribe((records: { label: string, rowIndex: number }[]) => {
      records.forEach((record: { label: string, rowIndex: number }) => {
        if (record.rowIndex === this.rowIndex && record.label === this.control.label) {
          this.formControl.disable();
        }
      });
    });

    this.setLineControlValidatorsSubscription = this.formDataService.setLineControlValidators$.subscribe((data: { label: string, rowIndex: number, required: boolean }) => {
      if (data.rowIndex === this.rowIndex && data.label === this.control.label) {
        this.setControlValidators(data.required);
      }
    });

    this.setLineControlListValidatorsSubscription = this.formDataService.setLineControlListValidators$.subscribe((records: { label: string, rowIndex: number, required: boolean }[]) => {
      records.forEach((record: { label: string, rowIndex: number, required: boolean }) => {
        if (record.rowIndex === this.rowIndex && record.label === this.control.label) {
          this.setControlValidators(record.required);
        }
      });
    });

    this.readonlyControlSubscription = this.formDataService.readonlyControl$.subscribe((label: string) => {
      if (label === this.control.label) {
        this.control.readonly = true;
      }
    });

    this.readonlyControlsListSubscription = this.formDataService.readonlyControlsList$.subscribe((labels: string[]) => {
      labels.forEach((label: string) => {
        if (label === this.control.label) {
          this.control.readonly = true;
        }
      });
    });

    this.readonlyLineControlSubscription = this.formDataService.readonlyLineControl$.subscribe((data: { label: string, rowIndex: number }) => {
      if (data.rowIndex === this.rowIndex && data.label === this.control.label) {
        this.control.readonly = true;
      }
    });

    this.readonlyLineControlslistSubscription = this.formDataService.readonlyLineControlslist$.subscribe((records: { label: string, rowIndex: number }[]) => {
      records.forEach((record: { label: string, rowIndex: number }) => {
        if (record.rowIndex === this.rowIndex && record.label === this.control.label) {
          this.control.readonly = true;
        }
      });
    });

    this.enableControlSubscription = this.formDataService.enableControl$.subscribe((label: string) => {
      if (label === this.control.label) {
        this.formControl.enable();
      }
    });

    this.enableControlsListSubscription = this.formDataService.enableControlsList$.subscribe((labels: string[]) => {
      labels.forEach((label: string) => {
        if (label === this.control.label) {
          this.formControl.enable();
        }
      });
    });

    this.enableLineControlSubscription = this.formDataService.enableLineControl$.subscribe((data: { label: string, rowIndex: number }) => {
      if (data.rowIndex === this.rowIndex && data.label === this.control.label) {
        this.formControl.enable();
      }
    });

    this.enableLineControlsListSubscription = this.formDataService.enableLineControlsList$.subscribe((records: { label: string, rowIndex: number }[]) => {
      records.forEach((record: { label: string, rowIndex: number }) => {
        if (record.rowIndex === this.rowIndex && record.label === this.control.label) {
          this.formControl.enable();
        }
      });
    });

    this.setDateTimeMinMaxDatesSubscription = this.formDataService.setDateTimeMinMaxDates$.subscribe((controlData: { label: string; rowIndex: number, min: any, max: any }) => {
      if (controlData.rowIndex === this.rowIndex && controlData.label === this.control.label) {
        if (controlData.min) {
          this.datepickerConfig.minDate = controlData.min;
          this.datepickerConfig.outsideDays = 'hidden';
        }

        if (controlData.max) {
          this.datepickerConfig.maxDate = controlData.max;
          this.datepickerConfig.outsideDays = 'hidden';
        }
      }
    });

    if (this.control && this.control.type === this.fieldType.DropDown) {
      if (this.control.apiUrl) {
        this.dropdownApiCallDoneSubscription = this.formDataService.dropdownApiCallDone$.subscribe(() => {
          const cacheData = this.formDataService.GetDropDownApiDataCache(this.control.apiUrl);
          if (cacheData) {
            if (this.control.displayFormat) {
              this.items = this.buildDropdownItems(cacheData.data, this.control.displayFormat);
            } else {
              this.items = cacheData.data;
            }
            if (!this.update && this.control.initialValue) {
              this.formControl.setValue(this.control.initialValue);
            }
            this.ready = true;
          }
        });
        this.getDropdownItems(this.control.initialValue);
      } else {
        if (!this.update && this.control.initialValue) {
          this.formControl.setValue(this.control.initialValue);
        }
        this.ready = true;
      }
    } else if (this.control.type === this.fieldType.DateTime) {
      const current = new Date();
      if (this.control.disablePastDays === true) {
        this.datepickerConfig.minDate = {
          year: current.getFullYear(),
          month: current.getMonth() + 1,
          day: current.getDate()
        };
        this.datepickerConfig.outsideDays = 'hidden';
      }

      if (this.control.disableFutureDays === true) {
        this.datepickerConfig.maxDate = {
          year: current.getFullYear(), month:
            current.getMonth() + 1, day: current.getDate()
        };
        this.datepickerConfig.outsideDays = 'hidden';
      }

      if (this.control.showDropdownPopup === false) {
      } else {
        this.control.showDropdownPopup = true;
      }

      if (this.control.defaultSystemDate !== false && !this.formControl.value) {
        this.formControl.setValue({
          year: current.getFullYear(), month:
            current.getMonth() + 1, day: current.getDate()
        });
      }

      this.formControl.valueChanges.subscribe(value => {
        this.formControl.setValue(value, { emitEvent: false });
        this.changeControl();
      });

      this.ready = true;
    } else {
      this.ready = true;
    }
  }

  get formControl() { return this.formGroup.controls[this.control.label]; }

  setControlValidators(required: boolean) {
    if (required) {
      this.formControl.setValidators([Validators.required]);
    } else {
      if (this.control.type === FormFieldType.Email) {
        this.formControl.setValidators([Validators.email]);
      } else {
        this.formControl.setValidators([]);
      }
    }
  }

  getDropdownItems(value: string) {
    const cacheData = this.formDataService.GetDropDownApiDataCache(this.control.apiUrl);
    if (cacheData) {
      if (this.control.displayFormat) {
        this.items = this.buildDropdownItems(cacheData.data, this.control.displayFormat);
      } else {
        this.items = cacheData.data;
      }
      if (!this.update && this.control.initialValue) {
        this.formControl.setValue(this.control.initialValue);
      }
      this.ready = true;
    } else {
      if (!this.formDataService.dropDownApisPosted.includes(this.control.apiUrl)) {
        this.formDataService.dropDownApisPosted.push(this.control.apiUrl);
        this.restService.get(this.control.apiUrl).subscribe((response: any) => {
          let apiData = [];
          if (response.model) {
            if (this.control.displayFormat) {
              this.items = this.buildDropdownItems(response.model, this.control.displayFormat);
            } else {
              this.items = response.model;
            }
            apiData = response.model;
          } else {
            apiData = [];
            this.items = [];
          }

          this.formDataService.SetDropDownApiDataCache({
            url: this.control.apiUrl,
            data: apiData
          });
          this.formDataService.dropdownApiCallDone$.next();

          if (!this.update && value) {
            this.formControl.setValue(value);
          }
          this.ready = true;
        }, error => {
          this.items = [];
          this.ready = true;
        });
      }
    }
  }

  buildDropdownItems(data: any[], format: string): any[] {
    data.forEach((item: any) => {
      let value: string = format;
      for (let key in item) {
        if (item[key]) {
          value = value.replace('[' + key + ']', item[key]);
        } else {
          value = value.replace('[' + key + ']', '');
        }
      }

      item.displayValue = value;
    });

    this.control.bindLabel = 'displayValue';
    return data;
  }

  changeDropdown() {
    if (this.control.target) {
      this.control.target.forEach((target: TargetField) => {
        this.fillDropdownItem(target);
      });
    }
    this.change.emit(this.control.label);
    this.leave.emit();
  }

  fillDropdownItem(target: TargetField) {
    if (target.type === FormFieldType.DropDown) {
      const dropdown = this.formGroup.controls[target.label];
      if (dropdown) {
        const item: FieldItemType = this.items.filter((i: FieldItemType) => i[this.control.bindLabel] === this.formControl.value)[0];
        if (item) {
          this.restService.get(item.api).subscribe((response: any) => {
            if (response.model) {
              this.formFielService.updateDropdownItem$.next({
                label: target.label,
                items: response.model,
                bindLabel: item.bindLabel,
                bindValue: item.bindValue,
                rowIndex: this.rowIndex
              });
            }
          });
        }
      }
    }
  }

  changeControl() {
    if (this.control.type === FormFieldType.DateTime) {
      this.change.emit(this.control.label);
      this.leaveControl();
    } else {
      this.change.emit(this.control.label);
    }
  }

  leaveControl() {
    this.leave.emit();
  }

  selectDropdownItem() {
    const modalRef = this.modal.open(SelectDropdownItemModalComponent, { size: 'lg' });
    modalRef.componentInstance.componentType = this.control.dropdownComponent;

    // modalRef.componentInstance
    modalRef.result.then((result: DropdownModalResult) => {
      if (result) {
        this.items = this.buildDropdownItems(result.records, this.control.displayFormat);
        if (result.selected && result.selected.length > 0) {
          this.formControl.setValue(result.selected[0][this.control.bindValue]);
        }
      }
    });
  }

  isDataExists(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value) {
      return this.restService.get(this.control.dataExistsApi + '=' + control.value)
        .pipe(
          map((response: any) => {
            if (response.status && response.model) {
              return null;
            } else {
              return {
                notexists: true
              };
            }
          })
        );
    } else {
      return of(null);
    }
  }

  isUniqueData(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.value) {
      return this.restService.get(this.control.uniqueApiUrl + '=' + control.value)
        .pipe(
          map((response: any) => {
            if (response.status && response.model) {
              return {
                unique: true
              };
            } else {
              return null;
            }
          })
        );
    } else {
      return of(null);
    }
  }

  onImageSelection(event) {
    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];
      const mimeType = image.type;

      if (mimeType.match(/image\/*/)) {
        const reader = new FileReader();
        reader.onload = (fileReaderEvent: any) => {
          // called once readAsDataURL is completed
          this.imageSource = fileReaderEvent.target.result;
        };

        // read file as data url
        reader.readAsDataURL(image);
        this.formControl.setValue(image);
      } else {
        this.toastr.warning('Please select the image file to upload!');
      }
    }
  }

  ngOnDestroy() {
    this.dropdownApiCallDoneSubscription ? this.dropdownApiCallDoneSubscription.unsubscribe() : null;
    this.updateDropdownItemSubscription ? this.updateDropdownItemSubscription.unsubscribe() : null;
    this.updateControlDataSubscription ? this.updateControlDataSubscription.unsubscribe() : null;
    this.updateLineControlDataSubscription ? this.updateLineControlDataSubscription.unsubscribe() : null;
    this.updateControlsListDataSubscription ? this.updateControlsListDataSubscription.unsubscribe() : null;
    this.updateLineControlsListDataSubscription ? this.updateLineControlsListDataSubscription.unsubscribe() : null;
    this.disableControlSubscription ? this.disableControlSubscription.unsubscribe() : null;
    this.disableControlsListSubscription ? this.disableControlsListSubscription.unsubscribe() : null;
    this.setControlValidatorsSubscription ? this.setControlValidatorsSubscription.unsubscribe() : null;
    this.setControlListValidatorsSubscription ? this.setControlListValidatorsSubscription.unsubscribe() : null;
    this.disableLineControlSubscription ? this.disableLineControlSubscription.unsubscribe() : null;
    this.disableLineControlsListSubscription ? this.disableLineControlsListSubscription.unsubscribe() : null;
    this.setLineControlValidatorsSubscription ? this.setLineControlValidatorsSubscription.unsubscribe() : null;
    this.setLineControlListValidatorsSubscription ? this.setLineControlListValidatorsSubscription.unsubscribe() : null;
    this.readonlyControlSubscription ? this.readonlyControlSubscription.unsubscribe() : null;
    this.readonlyControlsListSubscription ? this.readonlyControlsListSubscription.unsubscribe() : null;
    this.readonlyLineControlSubscription ? this.readonlyLineControlSubscription.unsubscribe() : null;
    this.readonlyLineControlslistSubscription ? this.readonlyLineControlslistSubscription.unsubscribe() : null;
    this.enableControlSubscription ? this.enableControlSubscription.unsubscribe() : null;
    this.enableControlsListSubscription ? this.enableControlsListSubscription.unsubscribe() : null;
    this.enableLineControlSubscription ? this.enableLineControlSubscription.unsubscribe() : null;
    this.enableLineControlsListSubscription ? this.enableLineControlsListSubscription.unsubscribe() : null;
    this.setDateTimeMinMaxDatesSubscription ? this.setDateTimeMinMaxDatesSubscription.unsubscribe() : null;
  }
}
