export enum FormFieldType {
  TextBox,
  Number,
  DropDown,
  Radio,
  Checkbox,
  TextArea,
  PhoneNumber,
  Email,
  DateTime,
  Password,
  ImageUpload
}
