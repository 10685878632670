import { FormField } from 'src/app/Shared/models/formField';
import { FormFieldType } from 'src/app/Shared/models/formField.enum';

export const ResetCounterLineConfig: FormField[] = [
  {
    type: FormFieldType.TextBox,
    label: 'machineCode',
    name: 'Machine No',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'counterNo',
    name: 'Counter No',
    readonly:true
  },
  {
    type: FormFieldType.TextBox,
    label: 'lastCounter',
    name: 'Current CTR',
    readonly:true
  },
  {
    type: FormFieldType.Number,
    label: 'setupCounter',
    name: 'Reset CTR'
  },
  {
    type: FormFieldType.Number,
    label: 'differences',
    name: 'Differences',
    readonly:true
  },
  {
    type: FormFieldType.DropDown,
    label: 'reason',
    name: 'Reason',
    apiUrl: '/Reason/GetAll',
    bindValue: 'code',
    displayFormat: '[code]-[description]',
  },
  {
    type: FormFieldType.TextBox,
    label: 'ownership',
    name: 'Ownership',
    readonly:true
  }
];

export const ResetCounterCalculationConfig: FormField[] = [
  {
    type: FormFieldType.Number,
    label: 'totalExclSST',
    name: 'Total Excl. SST'
  },
  {
    type: FormFieldType.Number,
    label: 'sst',
    name: 'SST'
  },
  {
    type: FormFieldType.Number,
    label: 'totalInclSST',
    name: 'Total Incl. SST'
  }
];
