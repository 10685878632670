import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { EventDataModel } from '../models/eventDataModel';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest.service';
import { FormDataService } from '../services/form-data.service';
import { Utility } from '../services/utility.service';
import { CounterAmendmentLineConfig, CounterAmendmentCalculationConfig } from './line-counteramendment-config';
import { FormField } from '../models/formField';
import { FormFieldType } from '../models/formField.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'counteramendment-line-data',
  templateUrl: './line-counteramendment.component.html',
  styleUrls: ['./line-counteramendment.component.scss']
})
export class CounterAmendmentLineDataComponent implements OnInit, OnChanges, OnDestroy {

  ready: boolean = false;

  controls: FormField[] = CounterAmendmentLineConfig;
  calculationControls: FormField[] = CounterAmendmentCalculationConfig;
  lineDataForm: FormGroup;
  totlaExclSST: number = 0;
  sst: number = 0;
  totlaInclSST: number = 0;
  protected updateLineControlDataSubscription: Subscription;
  protected updateLineControlsListDataSubscription: Subscription;

  @Input() data: any[] = [];
  @Input() title: string;
  @Input() disable: boolean = false;

  @Output() change = new EventEmitter<EventDataModel>();
  @Output() leave = new EventEmitter<any>();
  @Output() changeCalculation = new EventEmitter<EventDataModel>();
  @Output() calculationData = new EventEmitter<any>();
  @Output() customButtonClick = new EventEmitter<string>();

  get items() { return this.lineDataForm.controls.items as FormArray; }

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private formDataService: FormDataService,
    private utility: Utility) { }

  ngOnInit() {
    if (!this.data || this.data.length === 0) {
      this.data = [
        {
          itemNo: '',
          oum: '',
          description: ''
        },
        {
          itemNo: '',
          oum: '',
          description: ''
        },
        {
          itemNo: '',
          oum: '',
          description: ''
        }
      ];
    }

    if (this.calculationControls && this.calculationControls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.calculationControls.length; i++) {
        const control: FormField = this.calculationControls[i];
        let validators: any[] = [];
        if (control.required) {
          validators.push(Validators.required);
        }
        if (control.type === FormFieldType.Email) {
          validators.push(Validators.email);
        }
        group[control.label] = new FormControl(null, validators);
      }
      group.items = new FormArray([]);
      this.lineDataForm = this.fb.group(group);
    } else {
      this.lineDataForm = this.fb.group({
        items: new FormArray([])
      });
    }
    this.generateItemsFormArray(this.data);

    this.updateLineControlDataSubscription = this.formDataService.updateLineControlData$.subscribe((data: EventDataModel) => {
      const itemGroup = this.items.controls[data.rowIndex] as FormGroup;
      if (itemGroup) {
        itemGroup.controls[data.control].setValue(data.data);
      }
    });

    this.updateLineControlsListDataSubscription = this.formDataService.updateLineControlsListData$.subscribe((items: EventDataModel[]) => {
      items.forEach((item: EventDataModel) => {
        const itemGroup = this.items.controls[item.rowIndex] as FormGroup;
        if (itemGroup) {
          itemGroup.controls[item.control].setValue(item.data);
        }
      });
    });
  }

  ngOnChanges() {
    if (this.data && this.data.length > 0) {
      for (let i = 0; i < this.data.length; i++) {
        this.updateLineData(this.data[i], i);
      }
    }
  }

  generateItemsFormArray(data: any[]) {
    data.forEach((item: any) => {
      this.createItemFormGroup(item);
    });
    this.ready = true;
  }

  createItemFormGroup(item: any) {
    if (this.controls && this.controls.length > 0) {
      let group: any = {};
      for (let i = 0; i < this.controls.length; i++) {
        const control: FormField = this.controls[i];
        let validators: any[] = [];
        if (control.required) {
          validators.push(Validators.required);
        }
        if (control.type === FormFieldType.Email) {
          validators.push(Validators.email);
        }

        let data: any;
        if (control.type === FormFieldType.DateTime) {
          data = this.utility.convertStringToDateObj(item[control.label]);
        } else {
          data = item[control.label];
        }
        group[control.label] = new FormControl(data, validators);
      }
      this.items.push(this.fb.group(group));
    }
  }

  updateLineData(item: any, index: number) {
    const lines = this.items.controls as [];
    const line: FormGroup = lines[index];
    if (this.controls && this.controls.length > 0) {
      for (let i = 0; i < this.controls.length; i++) {
        const control: FormField = this.controls[i];
        line.controls[control.label].setValue(item[control.label]);
      }
    }
  }

  changeControl(label: string, index: number) {
    const itemGroup = this.items.controls[index] as FormGroup;
    this.change.emit({
      control: label,
      data: itemGroup.controls[label].value,
      rowIndex: index
    });
  }

  leaveControl() {
    this.leave.emit(this.items.value);
  }

  changeLineCalculationControl(label: string) {
    if (typeof label === 'string') {
      this.change.emit({
        control: label,
        data: this.lineDataForm.controls[label].value
      });
    }
  }

  leaveCalculationControl() {
    let data = this.lineDataForm.value;
    delete data.items;
    this.calculationData.emit(data);
  }

  addLine() {
    this.createItemFormGroup({});
    this.leave.emit(this.items.value);
  }

  deleteLine(row: number) {
    let lineRows = this.items.controls as [];
    if (lineRows.length > 1) {
      lineRows.splice(row, 1);
    }
    this.leave.emit(this.items.value);
  }

  customButtonEvent(label: string) {
    this.customButtonClick.emit(label);
  }

  ngOnDestroy() {
    this.updateLineControlDataSubscription ? this.updateLineControlDataSubscription.unsubscribe() : null;
    this.updateLineControlsListDataSubscription ? this.updateLineControlsListDataSubscription.unsubscribe() : null;
  }
}

