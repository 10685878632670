// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   api: 'http://175.139.186.225:81/vosapi2.0/api',
  //api: 'http://122.0.18.147/vosapi/api',
  //api: 'https://vos.atlasasia.co/vosapi3.0/api', 
  // api: 'https://vos.atlasasia.co/vosapilive/api',

  report: 'https://vos.atlasasia.co/vosreports3.0/Report/',
  production: false,
  sessionTimeout: 30
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
